import { useHandleConversation } from 'iastart/hooks/useHandleConversation'
import { getMessages } from 'iastart/store/slices/conversationSlice'
import { useSelector } from 'react-redux'
import { RootState } from 'core/store'
import {
  IApplicationConversation,
  IMessage,
} from 'core/interfaces/Conversation'
import { ConversationComponent } from 'core/components/conversation/ConversationComponent'

export const ConversationContent: React.FC<{
  application: IApplicationConversation
}> = ({ application }) => {
  const { handleConverse, isLoading, handleResetConversation } =
    useHandleConversation(application.applicationName)
  const messages: IMessage[] = useSelector((state: RootState) =>
    getMessages(state, application.applicationName)
  )

  return (
    <div className="flex items-center w-2/3 mx-auto h-full">
      <ConversationComponent
        application={application}
        messages={messages}
        handleConverse={(msg) => handleConverse(msg)}
        isLoading={isLoading}
        handleResetConversation={handleResetConversation}
      />
    </div>
  )
}
