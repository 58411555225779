import React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useMatch } from 'react-router'
import { ReactComponent as BackArrowIcon } from 'core/assets/images/icones/backArrow.svg'
import { NavLink, useParams } from 'react-router-dom'
import { ROUTE_PATHS } from 'core/constants/routePath'
import { IApplication } from 'core/interfaces/Application'
interface PromptNavigationProps {
  application: IApplication
}

export const PromptNavigation: React.FC<PromptNavigationProps> = ({
  application,
}) => {
  const { t } = useTranslation()
  const isEditPromptPage = useMatch(ROUTE_PATHS.PROMPT_EDIT)
  const { idPrompt } = useParams()

  return (
    <div className="prompt-navigation">
      <div className="flex flex-row h-full items-center w-full flex-1">
        <div className="flex flex-col w-full">
          <h3>{t('prompts.title')}</h3>
          {isEditPromptPage ? (
            <NavLink
              to={generatePath(ROUTE_PATHS.PROMPT, {
                idApplication: application.id,
                idPrompt: idPrompt,
              })}
              className="navLink mt-0"
            >
              <BackArrowIcon className="icon" />
              <span>{t('prompts.goToPrompt')}</span>
            </NavLink>
          ) : (
            <NavLink
              to={generatePath(ROUTE_PATHS.PROMPTS, {
                idApplication: application.id,
              })}
              className="navLink mt-0"
            >
              <BackArrowIcon className="icon" />
              <span>{t('prompts.goToApplication')}</span>
            </NavLink>
          )}
        </div>
      </div>
    </div>
  )
}
