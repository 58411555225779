import React from 'react'
import Loader from 'core/components/common/Loader'
import { useAppIastart } from 'iastart/hooks/useAppIastart'
import { ConversationContent } from 'iastart/components/ConversationContent'
import { useTranslation } from 'react-i18next'
import { PromptTest } from 'iastart/components/PromptTest'

export const IaStartApps = () => {
  const { t } = useTranslation()
  const { isApplicationLoading, application } = useAppIastart()
  if (isApplicationLoading) {
    return (
      <Loader
        center
        className="h-[calc(100vh-180px)] flex items-center justify-center"
      />
    )
  }
  return (
    <>
      {application &&
        (application.service.id === 2 || application.service.id === 4 ? (
          <ConversationContent
            application={{
              accroche: t('iastart.application.accroche', {
                application: application.nomApplication,
              }),
              applicationName: application.nomTechnique,
              placeholder: t('iastart.placeholder'),
            }}
          />
        ) : (
          <PromptTest application={application} />
        ))}
    </>
  )
}
