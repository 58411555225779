import React, { SetStateAction } from 'react'
import { ITag } from 'core/interfaces/Tag'
import { ModalHeader } from 'core/components/common/ModalHeader'
import ModalWrapper from 'core/components/common/ModalWrapper'
import { ModalBody } from 'core/components/common/ModalBody'
import { useTranslation } from 'react-i18next'
import { TagForm } from 'lauria/components/tags/TagForm'

export const FormTagModal: React.FC<{
  open: boolean
  setOpen: (value: SetStateAction<boolean>) => void
  tag: ITag | null
}> = ({ open, setOpen, tag }) => {
  const { t } = useTranslation()
  const hide = (event: any) => {
    event.preventDefault()
    setOpen(false)
  }

  return (
    <ModalWrapper
      classNames={{ modal: 'modal modal-tag' }}
      onClose={() => setOpen(false)}
      open={open}
      showCloseIcon={false}
    >
      <ModalHeader titleClassName="modal-title mt-[20px]">
        {tag ? t('tags.edit.title') : t('tags.form.create.title')}
      </ModalHeader>
      <ModalBody className="mx-[30px] my-[20px]">
        <TagForm cancelAction={hide} setModalOpen={setOpen} tag={tag} />
      </ModalBody>
    </ModalWrapper>
  )
}
