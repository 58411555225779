import { ReactComponent as Avatar } from 'lauria/assets/images/common/avatar.svg'
import { ReactComponent as AvatarDarkMobile } from 'lauria/assets/images/common/avatarDarkMobile.svg'
import { FC, useContext } from 'react'
import { DarkThemeContext } from 'core/contexts/DarkThemeContext'

export const UserCardMobile: FC<{ username: string }> = ({ username }) => {
  const { isDarkTheme } = useContext(DarkThemeContext)

  return (
    <div className="card-user-mobile">
      {isDarkTheme ? (
        <AvatarDarkMobile />
      ) : (
        <Avatar width="45px" height="45px" />
      )}
      <div className="username">{username}</div>
    </div>
  )
}
