import { ReactComponent as MenuIcone } from 'lauria/assets/images/mobile/menuIcon.svg'
import { ReactComponent as MenuIconeDark } from 'lauria/assets/images/mobile/menuIconDark.svg'
import { ReactComponent as FilterButton } from 'lauria/assets/images/mobile/filterButton.svg'
import { ReactComponent as FilterButtonDark } from 'lauria/assets/images/mobile/filterButtonDark.svg'
import { ReactComponent as TagButton } from 'lauria/assets/images/tags/tagButton.svg'
import { ReactComponent as TagButtonDark } from 'lauria/assets/images/tags/tagButtonDark.svg'
import { ReactComponent as PlusButton } from 'lauria/assets/images/mobile/plusButton.svg'
import { ReactComponent as PlusButtonDark } from 'lauria/assets/images/mobile/plusButtonDark.svg'
import { DarkThemeContext } from 'core/contexts/DarkThemeContext'
import React, { useContext, useState } from 'react'
import { generatePath, useNavigate } from 'react-router'
import { ROUTE_PATHS } from 'core/constants/routePath'

export const MobileMenu: React.FC<{
  openNewAppModal: () => void
  openFilterModal: () => void
  displayAddButton?: boolean
}> = ({ openNewAppModal, openFilterModal, displayAddButton = true }) => {
  const { isDarkTheme } = useContext(DarkThemeContext)
  const navigate = useNavigate()
  const [hide, setHide] = useState<boolean>(true)
  const displayMenu = () => {
    setHide(!hide)
  }
  const goToTagOptions = () => {
    navigate(generatePath(ROUTE_PATHS.TAG_ADMIN))
  }
  return (
    <div
      className="md:hidden absolute bottom-3.5 right-3.5 flex flex-row"
      onClick={displayMenu}
    >
      {isDarkTheme ? (
        <>
          <TagButtonDark
            className={`-mr-3 ${hide ? 'hidden' : ''}`}
            onClick={goToTagOptions}
          />
          <FilterButtonDark
            className={`-mr-3 ${hide ? 'hidden' : ''}`}
            onClick={openFilterModal}
          />
          {displayAddButton && (
            <PlusButtonDark
              className={`-mr-3 ${hide ? 'hidden' : ''}`}
              onClick={openNewAppModal}
            />
          )}
          <MenuIconeDark />
        </>
      ) : (
        <>
          <TagButton
            className={`-mr-3 ${hide ? 'hidden' : ''}`}
            onClick={goToTagOptions}
          />
          <FilterButton
            className={`-mr-3 ${hide ? 'hidden' : ''}`}
            onClick={openFilterModal}
          />
          {displayAddButton && (
            <PlusButton
              className={`-mr-3 ${hide ? 'hidden' : ''}`}
              onClick={openNewAppModal}
            />
          )}
          <MenuIcone />
        </>
      )}
    </div>
  )
}
