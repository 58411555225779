import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import React, { useContext, useEffect, useState } from 'react'
import { KpiPromptContext } from 'lauria/contexts/KpiPromptContext'
import { usePromptKpi } from 'lauria/hooks/usePromptKpi'
import { BLUE_COLOR, RED_COLOR, YELLOW_COLOR } from 'core/constants/kpi'
import { KpiCustomTooltip } from 'lauria/components/kpi/KpiCustomTooltip'
// eslint-disable-next-line import/no-unresolved
import { Payload } from 'recharts/types/component/DefaultTooltipContent'
import { KpiLineCard } from 'lauria/components/kpi/KpiLineCard'
import Loader from 'core/components/common/Loader'

export const KpiChart: React.FC<{ isLoading: boolean }> = ({ isLoading }) => {
  const { kpis, selectedPrompts, activeMetric, period, valueType } =
    useContext(KpiPromptContext)
  const { transformData } = usePromptKpi()
  const [formattedData, setFormattedData] = useState<any[]>([])
  const [showLine, setShowLine] = useState(true)
  useEffect(() => {
    setFormattedData(
      transformData(
        kpis,
        valueType === 'TOTAL'
          ? activeMetric.totalField
          : activeMetric.moyenneField,
        period
      )
    )
  }, [valueType, activeMetric, kpis])
  const colors = [RED_COLOR, YELLOW_COLOR, BLUE_COLOR]
  if (isLoading) {
    return (
      <div className="w-full h-[445px] flex items-center justify-center">
        <Loader pageCenter />
      </div>
    )
  }

  return (
    <div>
      <div className="py-6 pl-0 pr-8 bg-white dark:bg-black">
        <ResponsiveContainer width="100%" height={350}>
          <ComposedChart
            data={formattedData}
            margin={{ top: 20, right: 0, left: 0, bottom: 0 }}
            barSize={30}
          >
            <CartesianGrid horizontal={true} vertical={false} />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip<number, string>
              cursor={false}
              shared={false}
              content={({ active, payload }) => (
                <KpiCustomTooltip
                  active={active}
                  payload={payload as Payload<number, string>[]}
                />
              )}
            />
            {showLine && (
              <Line
                type="linear"
                dataKey={valueType === 'TOTAL' ? 'total' : 'average'}
                stroke="#3D9FB4"
                dot={false}
                strokeWidth={2}
                name={valueType === 'TOTAL' ? 'Total' : 'Moyenne'}
              />
            )}
            {selectedPrompts.map((promptId, index) =>
              activeMetric.type === 'pertinence' ? (
                <Line
                  key={promptId}
                  type="linear"
                  dataKey={promptId}
                  stroke={colors[index]}
                  dot={false}
                />
              ) : (
                <Bar
                  key={promptId}
                  dataKey={promptId}
                  stackId="stack"
                  fill={colors[index]}
                  className="cursor-pointer"
                />
              )
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      <div>
        <KpiLineCard
          showLine={showLine}
          setShowLine={setShowLine}
          valueType={valueType}
        />
      </div>
    </div>
  )
}
