import React from 'react'
import { Outlet } from 'react-router'
import { useSelector } from 'react-redux'
import { promptLayout } from 'lauria/store/slices/promptLayout'
import { PromptSubHeader } from 'lauria/components/prompts/PromptSubHeader'
import { PromptSubHeaderMobile } from 'lauria/components/prompts/PromptSubHeaderMobile'

export const Prompts = () => {
  const { application } = useSelector(promptLayout)
  if (!application) {
    return null
  }
  return (
    <div className="prompt-page">
      <PromptSubHeader application={application} />
      <PromptSubHeaderMobile application={application} />
      <div className="prompt-content">
        <Outlet />
      </div>
    </div>
  )
}
