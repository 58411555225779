import { Outlet } from 'react-router'
import React from 'react'
import { LayoutWrapper } from 'core/components/layout/LayoutWrapper'

export const Layout: React.FC<{
  itemsCenter?: boolean
  displayProfileIcone?: boolean
}> = ({ itemsCenter = false, displayProfileIcone = false }) => {
  return (
    <LayoutWrapper
      layoutClassName="layout-container"
      displayProfileIcone={displayProfileIcone}
    >
      <div className={`outlet-container ${itemsCenter ? 'items-center' : ''}`}>
        <Outlet />
      </div>
    </LayoutWrapper>
  )
}
