import {
  useForm,
  useFieldArray,
  SubmitHandler,
  UseFormGetValues,
  useController,
} from 'react-hook-form'
import React, { useEffect, useState } from 'react'
import { useAskPromptMutation } from 'core/api/ask'
import { PromptForm } from 'core/interfaces/Prompt'
import { IModeleAnswer, IPromptAskResponse } from 'core/interfaces/Ask'

export type PromptTestFormData = {
  fields: { name: string; value: string }[]
  modele1: string
  modele2?: string
}

export const usePromptTest = (
  getFormPromptValues: UseFormGetValues<PromptForm>,
  assistedMode: boolean
) => {
  const [askPrompt] = useAskPromptMutation()
  const [isLoading, setIsLoading] = useState(false)
  const [answers, setAnswers] = React.useState<IModeleAnswer[]>([])
  const [variables, setVariables] = useState<string[]>([])
  const extractVariablesFromPrompt = (): string[] => {
    const regex = /(\{[a-zA-Z0-9]+\})/g
    const matches = []
    let match

    let text = ''
    if (assistedMode) {
      const assistedValues = [
        getFormPromptValues('role'),
        getFormPromptValues('tache'),
        getFormPromptValues('format'),
        getFormPromptValues('contexte'),
      ]
      text = assistedValues.filter(Boolean).join(' ')
    } else {
      text = getFormPromptValues('prompt') || ''
    }
    while ((match = regex.exec(text)) !== null) {
      matches.push(match[1].trim())
    }

    return matches
  }
  useEffect(() => {
    setVariables(extractVariablesFromPrompt())
  }, [])
  const refreshVars = () => {
    setVariables(extractVariablesFromPrompt())
  }
  const { control, handleSubmit, register, reset, getValues } =
    useForm<PromptTestFormData>({
      defaultValues: {
        fields: variables.map((field) => ({ name: field, value: '' })),
        modele1: getFormPromptValues('modele') || '',
        modele2: '',
      },
    })

  const { fields, replace } = useFieldArray({
    control,
    name: 'fields',
  })
  const modele1Field = useController({
    name: 'modele1',
    control,
    rules: { required: true },
  }).field
  useController({
    name: 'modele2',
    control,
    rules: { required: false },
  })
  useEffect(() => {
    replace(variables.map((variable) => ({ name: variable, value: '' })))
  }, [variables, replace])
  const onSubmit: SubmitHandler<PromptTestFormData> = async (data) => {
    setIsLoading(true)
    let text = ''
    if (assistedMode) {
      const assistedValues = [
        getFormPromptValues('role'),
        getFormPromptValues('tache'),
        getFormPromptValues('format'),
        getFormPromptValues('contexte'),
        getFormPromptValues('instruction'),
      ]
      text = assistedValues.filter(Boolean).join(' ')
    } else {
      text = getFormPromptValues('prompt') || ''
    }
    data.fields.forEach((field) => {
      const regex = new RegExp(`${field.name}`, 'g')
      text = text.replace(regex, field.value || '')
    })
    const bodyBase = {
      prompt: text,
      temperature: getFormPromptValues('temperature') ?? 0.5,
    }
    try {
      const promises: Array<{
        promise: Promise<IPromptAskResponse>
        modele: string
      }> = []
      const startTimes: Record<string, number> = {}
      const endTimes: Record<string, number> = {}

      if (data.modele1) {
        startTimes['modele1'] = performance.now()
        promises.push({
          promise: askPrompt({ ...bodyBase, modele: data.modele1 })
            .unwrap()
            .then((result) => {
              endTimes['modele1'] = performance.now() // Enregistrez le temps de fin pour modele1
              return result
            }),
          modele: 'modele1',
        })
      }

      if (data.modele2) {
        startTimes['modele2'] = performance.now()
        promises.push({
          promise: askPrompt({ ...bodyBase, modele: data.modele2 })
            .unwrap()
            .then((result) => {
              endTimes['modele2'] = performance.now() // Enregistrez le temps de fin pour modele2
              return result
            }),
          modele: 'modele2',
        })
      }

      const results = await Promise.allSettled(promises.map((p) => p.promise))
      setAnswers([])

      results.forEach((result, index) => {
        const modele = promises[index].modele
        const elapsedTime = endTimes[modele] - startTimes[modele]

        if (result.status === 'fulfilled') {
          setAnswers((prev) => [
            ...prev,
            {
              answer: result.value,
              time: elapsedTime,
              modeleName: index === 0 ? data.modele1 : data.modele2,
            },
          ])
        } else {
          console.error(`Erreur avec modele${index + 1}:`, result.reason)
        }
      })

      reset({
        fields: variables.map((variable) => ({
          name: variable,
          value: '',
        })),
      })
      setIsLoading(false)
    } catch (error) {
      console.error('Erreur lors des appels API:', error)
    }
  }

  return {
    register,
    handleSubmit,
    fields,
    onSubmit,
    replace,
    isLoading,
    answers,
    setAnswers,
    getFormTestValues: getValues,
    modele1Field,
    variables,
    refreshVars,
  }
}
