import React from 'react'
import ReactDOM from 'react-dom/client'
import App from 'App'
import reportWebVitals from 'reportWebVitals'
import 'i18n'
import { keycloak } from 'keycloak'
import { KeycloakProvider } from 'core/packages/KeycloakProvider'
import { UserProvider } from 'core/contexts/UserContext'
import store from 'core/store'
import { Provider } from 'react-redux'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <KeycloakProvider
      initOptions={{
        onLoad: 'login-required',
        checkLoginIframe: false,
      }}
      authClient={keycloak}
    >
      <UserProvider>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </UserProvider>
    </KeycloakProvider>
  </Provider>
)

reportWebVitals()
