import React, { SetStateAction } from 'react'
import { ITag } from 'core/interfaces/Tag'
import { ModalHeader } from 'core/components/common/ModalHeader'
import ModalWrapper from 'core/components/common/ModalWrapper'
import { ModalBody } from 'core/components/common/ModalBody'
import { useTranslation, Trans } from 'react-i18next'
import { useDeleteTagMutation } from 'lauria/api/tag'

export const DeleteTagModal: React.FC<{
  open: boolean
  setOpen: (value: SetStateAction<boolean>) => void
  tag: ITag | null
}> = ({ open, setOpen, tag }) => {
  const { t } = useTranslation()
  const [deleteTag] = useDeleteTagMutation()

  const hide = (event: any) => {
    event.preventDefault()
    setOpen(false)
  }
  const handleDelete = () => {
    if (tag) {
      deleteTag(tag.id)
      setOpen(false)
    }
  }

  return (
    <ModalWrapper
      classNames={{ modal: 'modal modal-tag' }}
      onClose={() => setOpen(false)}
      open={open}
      showCloseIcon={false}
    >
      <ModalHeader titleClassName="modal-title mt-[30px]">
        {t('tags.delete.title')}
      </ModalHeader>
      <ModalBody className="my-[30px] ml-[30px]">
        <div>
          <Trans i18nKey="tags.delete.texte" values={{ label: tag?.nomTag }} />
        </div>
        <div
          className={`text-right self-end w-full md:block mt-[30px] pr-[40px]`}
        >
          <button className="btn cancel hidden text-[14px]" onClick={hide}>
            {t('global.form.button.cancel')}
          </button>
          <button
            className="btn default w-full md:w-[220px] md:ml-30px text-[14px]"
            onClick={handleDelete}
          >
            {t('tags.delete.button')}
          </button>
        </div>
      </ModalBody>
    </ModalWrapper>
  )
}
