import { IAMessage } from 'core/components/conversation/IAMessage'
import {
  IApplicationConversation,
  IMessage,
  UserType,
} from 'core/interfaces/Conversation'
import { UserMessage } from 'core/components/conversation/UserMessage'
import { MessageInput } from 'core/components/conversation/MessageInput'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ReloadIcon } from 'core/assets/images/icones/refreshIcon.svg'

export const ConversationComponent: React.FC<{
  handleResetConversation?: (app?: string) => void
  handleConverse: (message: string) => void | boolean
  isLoading: boolean
  messages: IMessage[]
  application: IApplicationConversation
  withMessageLabel?: boolean
  withResetButton?: boolean
  smallAvatar?: boolean
}> = ({
  handleResetConversation = () => {},
  handleConverse,
  isLoading,
  messages,
  application,
  withMessageLabel = true,
  withResetButton = true,
  smallAvatar = false,
}) => {
  const [message, setMessage] = useState('')
  const { t } = useTranslation()
  const messagesContainerRef = useRef<HTMLDivElement | null>(null)

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages, isLoading])

  return (
    <>
      <div className="conversation">
        <div
          ref={messagesContainerRef}
          className="w-full flex-grow overflow-auto"
        >
          <div className="messages-wrapper">
            {application.accroche !== '' && (
              <IAMessage
                message={{
                  content: application.accroche,
                  type: UserType.ASSISTANT,
                  timestamp: Date.now(),
                }}
                application={application.applicationName}
                first
                smallAvatar={smallAvatar}
              />
            )}
            {messages.map((message, index) => {
              return message.type === UserType.ASSISTANT ? (
                <IAMessage
                  key={index}
                  message={message}
                  application={application.applicationName}
                  smallAvatar={smallAvatar}
                  first={index === 0}
                />
              ) : (
                <UserMessage
                  key={index}
                  message={message.content}
                  smallAvatar={smallAvatar}
                />
              )
            })}
            {isLoading && (
              <>
                {message !== '' && (
                  <UserMessage message={message} smallAvatar={smallAvatar} />
                )}
                <IAMessage
                  message={{
                    content: '',
                    type: UserType.ASSISTANT,
                    timestamp: Date.now(),
                  }}
                  application={application.applicationName}
                  isLoading
                  smallAvatar={smallAvatar}
                />
              </>
            )}
          </div>
        </div>
        <div className="message-input-wrapper">
          {withResetButton && (
            <div
              className="reload-conversation"
              onClick={() =>
                handleResetConversation(application.applicationName)
              }
            >
              <ReloadIcon className="mr-[10px]" />
              {t('iastart.newConversation')}
            </div>
          )}
          <MessageInput
            placeholderText={application.placeholder}
            onSend={(mess) => handleConverse(mess)}
            setMessage={setMessage}
            withLabel={withMessageLabel}
          />
        </div>
      </div>
    </>
  )
}
