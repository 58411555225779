import React, { ReactNode } from 'react'
import { DarkModeProvider } from 'core/contexts/DarkThemeContext'
import { LayoutHeader } from 'core/components/layout/LayoutHeader'
import { CustomToastContainer } from 'core/components/common/Toast'

interface LayoutWrapperProps {
  children: ReactNode
  layoutClassName?: string
  displayProfileIcone?: boolean
}

export const LayoutWrapper: React.FC<LayoutWrapperProps> = ({
  children,
  layoutClassName = 'layout-container',
  displayProfileIcone = false,
}) => {
  return (
    <DarkModeProvider>
      <div className={layoutClassName}>
        <LayoutHeader displayProfileIcone={displayProfileIcone} />
        {children}
        <CustomToastContainer />
      </div>
    </DarkModeProvider>
  )
}
