import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Loader from 'core/components/common/Loader'
import { PromptDistributionRow } from 'lauria/components/prompts/PromptDistributionRow'
import { RemovePromptModal } from 'lauria/components/prompts/RemovePromptModal'
import { usePromptDistribution } from 'lauria/hooks/usePromptDistribution'
import { PromptListHeader } from 'lauria/components/prompts/PromptListHeader'
import { PromptMobileMenu } from 'lauria/components/prompts/PromptMobileMenu'
import { PromptFilterModal } from 'lauria/components/prompts/PromptFilterModal'

export const PromptList = () => {
  const { t } = useTranslation()
  const [openFilter, setOpenFilter] = useState(false)

  const {
    setPromptData,
    promptDeleted,
    setPromptToDelete,
    displayedPrompts,
    showInactive,
    setShowInactive,
    openRemoveModal,
    setOpenRemoveModal,
    error,
    isLoading,
    isSaving,
    totalDistribution,
    removePrompt,
    savePrompts,
    idApplication,
    createPrompt,
  } = usePromptDistribution()

  if (isLoading || isSaving) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader pageCenter />
      </div>
    )
  }

  return (
    <>
      <div className="diffusion-prompts">
        <PromptListHeader
          createPrompt={createPrompt}
          setShowInactive={setShowInactive}
          showInactive={showInactive}
        />
        {displayedPrompts.length === 0 ? (
          <div className="text-gray-1325 mt-[20px]">
            {showInactive
              ? t('prompts.noPromptInactif')
              : t('prompts.noPromptActif')}
          </div>
        ) : (
          <>
            <div className="hidden md:flex flex-row font-semibold p-4 items-center w-full">
              <div className="w-5/12"></div>
              <div className="grid grid-cols-12 gap-10 w-7/12 items-center">
                <div className="col-span-2 ml-10px">
                  {t('prompts.diffusion.etat')}
                </div>
                <div className="col-span-3 flex flex-row items-center ml-10px">
                  {t('prompts.diffusion.distribution')}
                  <span
                    className={`ml-15px text-[18px] font-light ${
                      totalDistribution > 100 || error
                        ? 'text-red-1250'
                        : 'text-blue-1350'
                    }`}
                  >
                    {totalDistribution}%
                  </span>
                </div>
                <div className="col-span-5">
                  {t('prompts.diffusion.evaluationTitre')}
                </div>
                <div className="col-span-2"></div>
              </div>
            </div>
            <div>
              {displayedPrompts?.map((prompt) => (
                <PromptDistributionRow
                  key={prompt.id}
                  prompt={prompt}
                  setPromptData={setPromptData}
                  isDeleted={!!promptDeleted.find((p) => p.id === prompt.id)}
                  setOpenModal={setOpenRemoveModal}
                  setPromptToDelete={setPromptToDelete}
                  error={error}
                  idApplication={idApplication}
                />
              ))}
            </div>
            <div
              className={`diffusion-row ${showInactive ? 'md:flex hidden' : ''}`}
            >
              <div className="md:w-5/12"></div>
              <div className="md:w-7/12 w-2/3">
                <button
                  className="btn default md:w-[330px] w-full btn-diffusion"
                  onClick={savePrompts}
                >
                  <span className="md:block hidden">
                    {t('prompts.diffusion.submitButton')}
                  </span>
                  <span className="md:hidden block">
                    {t('global.form.button.save')}
                  </span>
                </button>
              </div>
            </div>
          </>
        )}
      </div>
      <PromptMobileMenu
        createPrompt={createPrompt}
        openFilterModal={() => setOpenFilter(true)}
      />
      <RemovePromptModal
        open={openRemoveModal}
        setOpen={setOpenRemoveModal}
        handleClick={removePrompt}
      />
      <PromptFilterModal
        open={openFilter}
        setOpen={setOpenFilter}
        setShowInactive={setShowInactive}
      />
    </>
  )
}
