import React from 'react'
import { KpiPromptContextProvider } from 'lauria/contexts/KpiPromptContext'
import { KpiPrompt } from 'lauria/components/kpi/KpiPrompt'

export const Kpi = () => {
  return (
    <KpiPromptContextProvider>
      <KpiPrompt />
    </KpiPromptContextProvider>
  )
}
