import { TagsOptions } from 'lauria/components/tags/TagsOptions'
import { useGetAllTagsWithApplicationsQuery } from 'lauria/api/tag'
import Loader from 'core/components/common/Loader'
import React, { useContext, useEffect, useState } from 'react'
import { CurrentTagContext } from 'lauria/contexts/CurrentTagContext'
import { TagsAppsList } from 'lauria/components/tags/TagsAppsList'
import { useTranslation } from 'react-i18next'
import { ReactComponent as TagIcon } from 'lauria/assets/images/tags/tagIcone.svg'
import { ReactComponent as AddIcon } from 'core/assets/images/icones/addIcone.svg'
import { ReactComponent as PlusButton } from 'lauria/assets/images/mobile/plusButton.svg'
import { ReactComponent as PlusButtonDark } from 'lauria/assets/images/mobile/plusButtonDark.svg'
import { DarkThemeContext } from 'core/contexts/DarkThemeContext'

export const TagsAdmin = () => {
  const {
    data: tags,
    isLoading,
    isFetching,
  } = useGetAllTagsWithApplicationsQuery()
  const { setCurrentTag } = useContext(CurrentTagContext)
  const { isDarkTheme } = useContext(DarkThemeContext)
  const [hidden, setHidden] = useState(false)
  const [openEdit, setOpenEdit] = useState<boolean>(false)
  const { t } = useTranslation()
  useEffect(() => {
    if (tags && tags.length > 0) {
      setCurrentTag(tags[0])
    }
  }, [tags])
  if (isLoading || isFetching) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader center />
      </div>
    )
  }

  if (!tags) {
    return null
  }

  return (
    tags && (
      <div className="tags-admin">
        <div
          className={`tags-options-wrapper relative flex-col ${hidden ? 'md:flex hidden' : 'flex'}`}
        >
          <div
            className="btn-without-bg tag-add-button text-white border-white "
            onClick={() => setOpenEdit(true)}
          >
            <AddIcon className="text-white mr-[10px]" />
            {t('tags.form.create.button')}
          </div>
          <div className="tag-title options">
            <TagIcon className="md:hidden flex tag-icon mr-[20px]" />
            {t('tags.optionTitle')}
          </div>
          <TagsOptions
            tags={tags}
            setHidden={setHidden}
            openEdit={openEdit}
            setOpenEdit={setOpenEdit}
          />
        </div>
        <div
          className={`tag-apps-list-wrapper ${hidden ? 'flex' : 'md:flex hidden'}`}
        >
          <TagsAppsList setHidden={setHidden} />
        </div>
        <div
          className={`md:hidden z-40 bottom-[20px] right-[20px] ${hidden ? 'hidden' : 'absolute'}`}
        >
          {isDarkTheme ? (
            <PlusButtonDark onClick={() => setOpenEdit(true)} />
          ) : (
            <PlusButton onClick={() => setOpenEdit(true)} />
          )}
        </div>
      </div>
    )
  )
}
