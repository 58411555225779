import React, { useContext, useEffect, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { Layout } from 'core/pages/Layout'
import 'core/assets/styles/style.css'
import { UseKeycloakService } from 'core/packages/KeycloakProvider'
import Loader from 'core/components/common/Loader'
import { Error404 } from 'core/components/error/Error404'
import ErrorHandler from 'core/components/error/ErrorHandler'
import { Error500 } from 'core/components/error/Error500'
import { BACKOFFICE_PATH, ROUTE_PATHS } from 'core/constants/routePath'
import { Dashboard } from 'lauria/pages/Dashboard'
import { PromptList } from 'lauria/pages/prompts/PromptList'
import { Prompt } from 'lauria/pages/prompts/Prompt'
import { PromptEdition } from 'lauria/pages/prompts/PromptEdition'
import { LayoutApplication } from 'lauria/pages/applications/LayoutApplication'
import { Tags } from 'lauria/pages/Tags'
import { Prompts } from 'lauria/pages/prompts/Prompts'
import { Kpi } from 'lauria/pages/prompts/Kpi'
import { Profiles } from 'lauria/pages/profiles/Profiles'
import { useGetMeRolesQuery } from 'core/api/users'
import { UserContext } from 'core/contexts/UserContext'
import { ProfileEdition } from 'lauria/pages/profiles/ProfileEdition'
import store, { persistor } from 'core/store'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { LayoutApp } from 'iastart/components/layout/LayoutApp'
import { IaStart } from 'iastart/pages/IaStart'
import { Conversation } from 'iastart/pages/Conversation'
import { IaStartApps } from 'iastart/pages/IaStartApps'

function App() {
  const kc = UseKeycloakService()
  const { setCurrentUser } = useContext(UserContext)
  const isLoading = !kc.isInitialized()
  const [shouldFetchRoles, setShouldFetchRoles] = useState(false)
  const { data } = useGetMeRolesQuery(undefined, {
    skip: !shouldFetchRoles,
  })
  useEffect(() => {
    if (kc.isInitialized()) {
      setShouldFetchRoles(true)
    }
  }, [kc.isInitialized()])
  useEffect(() => {
    if (data) {
      setCurrentUser(data)
    }
  }, [data])
  const {
    PROFILES,
    PROFILES_CREATE,
    PROFILES_EDIT,
    PROMPTS,
    PROMPT,
    PROMPT_CREATE,
    PROMPT_EDIT,
    ERROR,
    TAG_ADMIN,
    KPI,
    IASTART,
    IASTART_CONVERSATION,
    IASTART_APP,
  } = ROUTE_PATHS
  if (isLoading) {
    return (
      <Loader center className="h-screen flex items-center justify-center" />
    )
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ErrorHandler>
          <BrowserRouter>
            <Routes>
              <Route path={BACKOFFICE_PATH}>
                <Route path="" element={<Layout displayProfileIcone />}>
                  <Route path="" element={<Dashboard />} />
                  <Route path={TAG_ADMIN} element={<Tags />} />
                  <Route path={PROFILES} element={<Profiles />} />
                  <Route path={PROFILES_CREATE} element={<ProfileEdition />} />
                  <Route path={PROFILES_EDIT} element={<ProfileEdition />} />
                </Route>
                <Route path="" element={<LayoutApplication />}>
                  <Route path={PROMPTS} element={<Prompts />}>
                    <Route index element={<PromptList />} />
                    <Route path={KPI} element={<Kpi />} />
                  </Route>
                  <Route path={PROMPT} element={<Prompt />} />
                  <Route path={PROMPT_EDIT} element={<PromptEdition />} />
                  <Route path={PROMPT_CREATE} element={<PromptEdition />} />
                </Route>
              </Route>

              <Route path="" element={<Layout />}>
                <Route path="" element={<LayoutApp />}>
                  <Route path={IASTART} element={<IaStart />} />
                  <Route
                    path={IASTART_CONVERSATION}
                    element={<Conversation />}
                  />
                  <Route path={IASTART_APP} element={<IaStartApps />} />
                </Route>
              </Route>
              <Route path="" element={<Layout itemsCenter />}>
                <Route path={ERROR} element={<Error500 />} />
                <Route path="*" element={<Error404 />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </ErrorHandler>
      </PersistGate>
    </Provider>
  )
}

export default App
