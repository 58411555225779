import { IPromptDetail } from 'core/interfaces/Prompt'

import { ReactComponent as DeleteIcon } from 'core/assets/images/icones/supprimerIcon.svg'
import { ReactComponent as DeleteIconDark } from 'core/assets/images/icones/supprimerIconedark.svg'
import { useContext, useState } from 'react'
import { DarkThemeContext } from 'core/contexts/DarkThemeContext'
import { useTranslation } from 'react-i18next'

export const DeletePromptButton: React.FC<{
  prompt: IPromptDetail
  handleDelete: () => void
}> = ({ prompt, handleDelete }) => {
  const [isHovered, setIsHovered] = useState(false)
  const { isDarkTheme } = useContext(DarkThemeContext)
  const { t } = useTranslation()

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="flex flex-col items-end relative"
    >
      <button
        onClick={handleDelete}
        className="text-red-1025 hover:text-red-1250 dark:text-gray-1350 dark:hover:text-gray-1250"
      >
        {isDarkTheme ? <DeleteIconDark /> : <DeleteIcon />}
      </button>
      {prompt.actif && isHovered && (
        <span className="absolute top-full mt-1 ml-2 text-right w-[150px]">
          {t('prompts.diffusion.textDeletedActif')}
        </span>
      )}
    </div>
  )
}
