import { IApplication } from 'core/interfaces/Application'
import React from 'react'
import { ApplicationCard } from 'lauria/components/dashboard/ApplicationCard'
import { generatePath, useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { ReactComponent as PlusIcon } from 'core/assets/images/icones/plusIcon.svg'
import { ROUTE_PATHS } from 'core/constants/routePath'

export const ApplicationCardList: React.FC<{
  applications: IApplication[]
  openModal: () => void
}> = ({ applications, openModal }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const redirectApp = (appId: number) => {
    navigate(generatePath(ROUTE_PATHS.PROMPTS, { idApplication: appId }))
  }

  return (
    <div className="card-list hidden md:flex">
      {applications.map((app) => {
        return (
          <div key={app.id} className="flex flex-col">
            <ApplicationCard
              application={app}
              handleClick={() => redirectApp(app.id)}
            />
            <div className="w-[260px] h-auto flex flex-wrap mt-[5px]">
              {app.tags?.map((tag) => {
                return (
                  <div key={tag.id} className="tag-button mb-[5px]">
                    <span className="text-app">{tag.nomTag}</span>
                  </div>
                )
              })}
            </div>
          </div>
        )
      })}
      <button className="button-add-app" onClick={openModal}>
        <PlusIcon />
        <span className="uppercase ml-[15px] font-medium">
          {t('services.newApp')}
        </span>
      </button>
    </div>
  )
}
