import { baseApi } from 'core/api/base'
import { generatePath } from 'react-router'
import {
  IApplication,
  IApplicationPromptVariable,
} from 'core/interfaces/Application'

const APPLICATIONS_ENDPOINT = '/applications'
export const APPLICATION_ENDPOINT = `${APPLICATIONS_ENDPOINT}/:applicationId`
export const APPLICATION_PROMPT_VARIABLE_ENDPOINT = `${APPLICATIONS_ENDPOINT}/:applicationName/prompt-variables`
const applicationApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllApplications: builder.query<
      IApplication[],
      { tags?: number[]; services?: number[]; emptyTag?: boolean } | void
    >({
      query: ({ tags = [], services = [], emptyTag = false } = {}) => {
        const params = new URLSearchParams()

        tags.forEach((tag) => {
          params.append('tags.id[]', tag.toString())
        })

        services.forEach((service) => {
          params.append('service.id[]', service.toString())
        })

        if (emptyTag) {
          params.append('empty[tags]', 'true')
        }

        const queryString = params.toString()
        return `${generatePath(APPLICATIONS_ENDPOINT)}${queryString !== '' ? '?' + queryString : ''}`
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Application', id }) as const),
              { type: 'Application', id: 'LIST' },
            ]
          : [{ type: 'Application', id: 'LIST' }],
    }),
    getApplication: builder.query<IApplication, { applicationId: number }>({
      query: ({ applicationId }) =>
        generatePath(APPLICATION_ENDPOINT, {
          applicationId: applicationId.toString(),
        }),
      providesTags: (result, error, applicationId) => [
        { type: 'Application', applicationId },
      ],
    }),
    createApplication: builder.mutation<
      IApplication,
      {
        idService: number
        nomApplication: string
        nomTechnique: string
        tags?: number[]
      }
    >({
      query: ({ idService, nomApplication, nomTechnique, tags }) => ({
        url: generatePath(APPLICATIONS_ENDPOINT),
        method: 'POST',
        body: { idService, nomApplication, nomTechnique, tags },
      }),
      invalidatesTags: [{ type: 'Application', id: 'LIST' }],
    }),
    updateApplication: builder.mutation<
      IApplication,
      {
        idApplication: number
        idService: number
        nomApplication: string
        tags?: number[]
      }
    >({
      query: ({ idApplication, idService, nomApplication, tags }) => ({
        url: generatePath(APPLICATION_ENDPOINT, {
          applicationId: idApplication.toString(),
        }),
        method: 'PATCH',
        body: { idService, nomApplication, tags },
      }),
      invalidatesTags: (result, error, { idApplication }) => [
        { type: 'Application', idApplication },
      ],
    }),
    getApplicationPromptVariables: builder.query<
      IApplicationPromptVariable,
      { applicationName: string }
    >({
      query: ({ applicationName }) =>
        generatePath(APPLICATION_PROMPT_VARIABLE_ENDPOINT, {
          applicationName,
        }),
    }),
  }),
})

export const {
  useGetAllApplicationsQuery,
  useGetApplicationQuery,
  useCreateApplicationMutation,
  useUpdateApplicationMutation,
  useGetApplicationPromptVariablesQuery,
} = applicationApi
export { applicationApi }
