export const BACKOFFICE_PATH = '/backoffice'
export const ROUTE_PATHS = {
  DASHBOARD: BACKOFFICE_PATH,
  KPI: `${BACKOFFICE_PATH}/applications/:idApplication/prompts/kpi`,
  ERROR: '/error',
  PROFILES: `${BACKOFFICE_PATH}/profiles`,
  PROFILES_CREATE: `${BACKOFFICE_PATH}/profiles/create`,
  PROFILES_EDIT: `${BACKOFFICE_PATH}/profiles/:idProfile/edit`,
  PROMPT: `${BACKOFFICE_PATH}/applications/:idApplication/prompts/:idPrompt`,
  PROMPT_CREATE: `${BACKOFFICE_PATH}/applications/:idApplication/prompts/create`,
  PROMPT_EDIT: `${BACKOFFICE_PATH}/applications/:idApplication/prompts/:idPrompt/edit`,
  PROMPTS: `${BACKOFFICE_PATH}/applications/:idApplication/prompts`,
  TAG_ADMIN: `${BACKOFFICE_PATH}/tags`,
  IASTART: '/',
  IASTART_CONVERSATION: '/conversation/:usage',
  IASTART_APP: '/app/:app',
}
