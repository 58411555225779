import { baseApi } from 'core/api/base'
import { generatePath } from 'react-router'
import { IService } from 'core/interfaces/Service'

const SERVICES_ENDPOINT = '/services'
const SERVICE_ENDPOINT = `${SERVICES_ENDPOINT}/:serviceId`
const serviceApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getService: builder.query<IService, { serviceId: number }>({
      query: ({ serviceId }) =>
        generatePath(SERVICE_ENDPOINT, {
          serviceId: serviceId.toString(),
        }),
    }),
    getAllService: builder.query<IService[], void>({
      query: () => generatePath(SERVICES_ENDPOINT),
    }),
  }),
})

export const { useGetServiceQuery, useGetAllServiceQuery } = serviceApi
export { serviceApi }
