import { ReactComponent as PromptIcone } from 'lauria/assets/images/mobile/promptButton.svg'
import { ReactComponent as PromptIconeDark } from 'lauria/assets/images/mobile/promptButtonDark.svg'
import { ReactComponent as FilterButton } from 'lauria/assets/images/mobile/filterButton.svg'
import { ReactComponent as FilterButtonDark } from 'lauria/assets/images/mobile/filterButtonDark.svg'
import { ReactComponent as PlusButton } from 'lauria/assets/images/mobile/plusButton.svg'
import { ReactComponent as PlusButtonDark } from 'lauria/assets/images/mobile/plusButtonDark.svg'
import { DarkThemeContext } from 'core/contexts/DarkThemeContext'
import React, { useContext, useState } from 'react'

export const PromptMobileMenu: React.FC<{
  createPrompt: () => void
  openFilterModal: () => void
}> = ({ createPrompt, openFilterModal }) => {
  const { isDarkTheme } = useContext(DarkThemeContext)
  const [hide, setHide] = useState<boolean>(true)
  const displayMenu = () => {
    setHide(!hide)
  }

  return (
    <div
      className="md:hidden absolute bottom-3.5 right-3.5 flex flex-row"
      onClick={displayMenu}
    >
      {isDarkTheme ? (
        <>
          <FilterButtonDark
            className={`-mr-3 ${hide ? 'hidden' : ''}`}
            onClick={openFilterModal}
          />
          <PlusButtonDark
            className={`-mr-3 ${hide ? 'hidden' : ''}`}
            onClick={createPrompt}
          />
          <PromptIconeDark />
        </>
      ) : (
        <>
          <FilterButton
            className={`-mr-3 ${hide ? 'hidden' : ''}`}
            onClick={openFilterModal}
          />
          <PlusButton
            className={`-mr-3 ${hide ? 'hidden' : ''}`}
            onClick={createPrompt}
          />
          <PromptIcone />
        </>
      )}
    </div>
  )
}
