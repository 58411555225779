import { DocumentIconComponent } from 'core/components/documents/DocumentIcon'
import React from 'react'
import { FILE_EXTENSION_GROUPS } from 'core/constants/general'
import { ReactComponent as DeleteIcon } from 'core/assets/images/icones/deleteCard.svg'

export const DocumentCard: React.FC<{
  filename: string
  withDelete?: boolean
  deleteDoc?: () => void
}> = ({ filename, withDelete = false, deleteDoc }) => {
  const extension = filename.split('.').pop()
  if (!extension) {
    return null
  }
  const getType = (extension: string): string | null => {
    for (const [key, extensions] of Object.entries(FILE_EXTENSION_GROUPS)) {
      if (extensions.includes(extension.toLowerCase())) {
        return key
      }
    }
    return ''
  }
  return (
    <div className="card-doc relative">
      <div className="mx-[10px]">
        <DocumentIconComponent extension={extension} />
      </div>
      <div className="flex flex-col flex-grow mr-[10px]">
        <div className="w-[80px] overflow-hidden text-ellipsis whitespace-nowrap">
          {filename}
        </div>
        <div className="uppercase text-gray-1250 mt-[-5px]">
          {getType(extension)}
        </div>
      </div>
      {withDelete && (
        <div
          className="cursor-pointer absolute top-[-4px] right-[-4px]"
          onClick={deleteDoc}
        >
          <DeleteIcon />
        </div>
      )}
    </div>
  )
}
