import React, { useContext, useEffect, useState } from 'react'
import { IMetric } from 'core/interfaces/Kpi'
import { useTranslation } from 'react-i18next'
import { KpiPromptContext } from 'lauria/contexts/KpiPromptContext'
import { usePromptKpi } from 'lauria/hooks/usePromptKpi'
import { differenceInDays } from 'date-fns'

export const KpiCard: React.FC<{ metric: IMetric }> = ({ metric }) => {
  const { t } = useTranslation()
  const { getSumByField, checkIfEmpty, formattedTime } = usePromptKpi()
  const { activeMetric, setActiveMetric, kpis, oldKpis, end, start } =
    useContext(KpiPromptContext)
  const changeMetric = (metric: IMetric) => {
    setActiveMetric(metric)
  }
  const Icon = metric.icon
  const [kpiData, setKpiData] = useState({
    total: 0,
    moyenne: 0,
    isEmpty: true,
  })
  const [oldKpiData, setOldKpiData] = useState({
    total: 0,
    moyenne: 0,
    isEmpty: true,
  })
  const calculateKpiData = (data: any, metricType: string) => {
    const total = getSumByField(data, metric.totalField)
    const totalPertinence = getSumByField(
      data,
      'moyenne_pertinence',
      'total_evaluation'
    )
    const divisor =
      metricType === 'application'
        ? differenceInDays(end, start)
        : metricType === 'pertinence'
          ? getSumByField(data, 'total_evaluation') || 1
          : getSumByField(data, 'total_conversation') || 1

    return {
      total,
      moyenne:
        divisor > 0
          ? (metricType === 'pertinence' ? totalPertinence : total) / divisor
          : 0,
      isEmpty: checkIfEmpty(data),
    }
  }
  useEffect(() => {
    setKpiData(calculateKpiData(kpis, metric.type))
  }, [kpis])
  useEffect(() => {
    setOldKpiData(calculateKpiData(oldKpis, metric.type))
  }, [oldKpis])
  return (
    <div
      className={`kpi-card group ${activeMetric.type === metric.type ? 'active' : ''}`}
      onClick={() => changeMetric(metric)}
    >
      <div className="kpi-card-header-wrapper">
        <div className="kpi-header-icon group-hover:border-blue-1350">
          <Icon
            className={`group-hover:text-blue-1350 ${activeMetric.type === metric.type ? 'text-blue-1350' : ''}`}
          />
        </div>
        <div
          className={`kpi-header-title group-hover:border-blue-1350 group-hover:text-blue-1350 ${activeMetric.type === metric.type ? 'text-blue-1350' : ''}`}
        >
          {t(metric.title)}
        </div>
      </div>
      <div className="kpi-card-content">
        <div className="col col1 space-y-[-2px]">
          <span className="uppercase">{t(metric.titleCol1)}</span>
          {kpiData.isEmpty && oldKpiData.isEmpty ? (
            <span>{t('prompts.kpi.insufficient')}</span>
          ) : (
            <>
              <span
                className={`${kpiData.isEmpty ? 'text-[13px] text-gray-1250' : oldKpiData.total > 0 ? (oldKpiData.total > kpiData.total ? 'text-[18px] text-red-1250' : 'text-[18px] text-blue-1500') : 'text-[18px]'}`}
              >
                <>
                  {kpiData.isEmpty
                    ? t('prompts.kpi.insufficient')
                    : metric.type !== 'traitement'
                      ? Math.round(kpiData.total * 1000) / 1000
                      : formattedTime(kpiData.total)}
                  {!kpiData.isEmpty && metric.type === 'cout' ? '€' : ''}
                </>
              </span>
              {!oldKpiData.isEmpty && (
                <span>
                  <>
                    {metric.type !== 'traitement'
                      ? Math.round(oldKpiData.total * 1000) / 1000
                      : formattedTime(oldKpiData.total)}
                    {metric?.type === 'cout' ? '€' : ''}
                  </>
                </span>
              )}
            </>
          )}
        </div>
        <div className="col space-y-[-2px]">
          <span className="uppercase">{t(metric.titleCol2)}</span>
          {kpiData.isEmpty && oldKpiData.isEmpty ? (
            <span>{t('prompts.kpi.insufficient')}</span>
          ) : (
            <>
              <span
                className={`${kpiData.isEmpty ? 'text-[13px] text-gray-1250' : oldKpiData.moyenne > 0 ? (oldKpiData.moyenne > kpiData.moyenne ? 'text-[18px] text-red-1250' : 'text-[18px] text-blue-1500') : 'text-[18px]'}`}
              >
                {kpiData.isEmpty
                  ? t('prompts.kpi.insufficient')
                  : metric.type !== 'traitement'
                    ? Math.round(kpiData.moyenne * 1000) / 1000
                    : formattedTime(kpiData.moyenne)}
                {!kpiData.isEmpty && metric.type === 'cout' ? '€' : ''}
              </span>
              {!oldKpiData.isEmpty && (
                <span>
                  {metric.type !== 'traitement'
                    ? Math.round(oldKpiData.moyenne * 1000) / 1000
                    : formattedTime(oldKpiData.moyenne)}
                  {!kpiData.isEmpty && metric.type === 'cout' ? '€' : ''}
                </span>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
