import { CurrentTagProvider } from 'lauria/contexts/CurrentTagContext'
import React, { useContext } from 'react'
import { UserContext } from 'core/contexts/UserContext'
import { useNavigate } from 'react-router'
import { ROUTE_PATHS } from 'core/constants/routePath'
import Loader from 'core/components/common/Loader'
import { TagsAdmin } from 'lauria/components/tags/TagsAdmin'

export const Tags = () => {
  const { user: currentUser } = useContext(UserContext)
  const navigate = useNavigate()
  if (currentUser === null) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader center />
      </div>
    )
  }
  if (!currentUser?.superAdmin) {
    navigate(ROUTE_PATHS.DASHBOARD)
  }
  return (
    <CurrentTagProvider>
      <TagsAdmin />
    </CurrentTagProvider>
  )
}
