import React, { useEffect, useState } from 'react'
import { FieldLabel } from 'core/components/common/FieldLabel'
import {
  ControllerRenderProps,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { PromptForm } from 'core/interfaces/Prompt'
import { FormSelect } from 'core/components/common/FormSelect'
import { TemperatureSlider } from 'lauria/components/common/TemperatureSlider'
import { IModele } from 'core/interfaces/Modele'

interface PromptFormFieldsProps {
  nomField: ControllerRenderProps<PromptForm, 'nomPrompt'>
  errors: any
  assistedMode: boolean
  changeMode: (value: boolean) => void
  temperatureField: ControllerRenderProps<PromptForm, 'temperature'>
  modeleField: ControllerRenderProps<PromptForm, 'modele'>
  setValue: UseFormSetValue<PromptForm>
  modeleList: IModele[]
  register: UseFormRegister<PromptForm>
}

export const PromptFormFields: React.FC<PromptFormFieldsProps> = ({
  nomField,
  errors,
  assistedMode,
  changeMode,
  temperatureField,
  setValue,
  modeleList,
  register,
  modeleField,
}) => {
  const { t } = useTranslation()
  const [temperature, setTemperature] = useState<number>(
    temperatureField.value ?? 0.5
  )
  const modeleOptions = modeleList.map((modele) => ({
    value: modele.nom,
    label: modele.label,
  }))
  useEffect(() => {
    setValue('temperature', temperature)
  }, [temperature])

  return (
    <div className="flex flex-col w-full">
      <div className="flex md:flex-row flex-col w-full flex-grow mb-[20px]">
        <div className="flex flex-col w-2/3">
          <FieldLabel
            className="prompt-field-label"
            label={t('prompts.form.nomPromptLabel')}
            required
          />
          <div className="md:w-2/3">
            <input
              type="text"
              id="nomPrompt"
              placeholder={t('global.form.input.placeholder')}
              className={`form-input w-full ${errors.nomPrompt ? 'error' : ''}`}
              {...nomField}
            />
            {errors.nomPrompt && (
              <p className="form-error">
                {errors.nomPrompt.type === 'required'
                  ? t('global.form.error.mandatory')
                  : errors.nomPrompt.message}
              </p>
            )}
          </div>
        </div>
        <div className="flex flex-col md:ml-auto md:mt-0 mt-[20px]">
          <FieldLabel
            className="prompt-field-label"
            label={t('prompts.form.modeRedactionLabel')}
          />
          <div className="flex flex-row">
            <div
              className={`btn-mode rounded-l-[5px] ${!assistedMode ? 'selected' : ''}`}
              onClick={() => changeMode(false)}
            >
              {t('prompts.form.modeExpert')}
            </div>
            <div
              className={`btn-mode rounded-r-[5px] ${assistedMode ? 'selected' : ''}`}
              onClick={() => changeMode(true)}
            >
              {t('prompts.form.modeAccompagne')}
            </div>
          </div>
        </div>
      </div>
      <div className="prompt-common-field-section">
        <div className="flex flex-col">
          <FieldLabel
            className="md:py-3 h-8 md:h-[30px] mb-[10px]"
            label={t('prompts.form.modeleDispoLabel')}
          />
          <FormSelect
            wrapperClassName="w-[200px]"
            selectClassName="h-[30px] w-full form-input"
            fieldName="modele"
            options={modeleOptions}
            register={register}
            value={
              modeleField.value === ''
                ? modeleList.find((modele) => modele.defaut)?.nom
                : modeleField.value
            }
          />
        </div>
        <div className="trait-vertical"></div>
        <div className="flex flex-col md:mt-0 mt-[20px]">
          <FieldLabel
            className="prompt-field-label"
            label={t('prompts.form.temperatureLabel')}
          />
          <TemperatureSlider
            value={temperature}
            setValue={setTemperature}
            edition
          />
          <input type="hidden" {...temperatureField} />
        </div>
      </div>
    </div>
  )
}
