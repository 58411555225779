import { baseApi } from 'core/api/base'
import {
  IConverseBody,
  IConverseResponse,
} from 'iastart/interfaces/Conversation'

const CONVERSE_ENDPOINT = '/converse'

const conversationApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    converse: builder.mutation<IConverseResponse, IConverseBody>({
      query: (conversation) => ({
        url: CONVERSE_ENDPOINT,
        method: 'POST',
        body: conversation,
      }),
      invalidatesTags: [],
    }),
  }),
})

export const { useConverseMutation } = conversationApi
