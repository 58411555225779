import { useParams } from 'react-router-dom'
import { usageParams } from 'iastart/constants/usage'
import { generatePath, useNavigate } from 'react-router'
import { useContext, useEffect } from 'react'
import { ROUTE_PATHS } from 'core/constants/routePath'
import { ConversationContent } from 'iastart/components/ConversationContent'
import { useTranslation } from 'react-i18next'
import { CurrentHeaderContext } from 'iastart/contexts/CurrentHeaderContext'

export const Conversation = () => {
  const { usage } = useParams()
  const { t } = useTranslation()
  const current = usageParams.find((param) => param.usageKey === usage)
  const navigate = useNavigate()
  const { setHeader } = useContext(CurrentHeaderContext)
  useEffect(() => {
    if (!current) {
      navigate(generatePath(ROUTE_PATHS.IASTART_CONVERSATION))
    } else {
      setHeader({
        svgPath: current.logo,
        svgPathDark: current.logoDark,
        accroche: `iastart.usages.${current.usageKey}.text`,
        column: true,
      })
    }
  }, [])

  return current ? (
    <ConversationContent
      application={{
        accroche: t(`iastart.usages.${current.usageKey}.accroche`),
        applicationName: current.application,
        placeholder: t(`iastart.usages.${current.usageKey}.placeholder`),
      }}
    />
  ) : null
}
