import { generatePath, Outlet, useLocation, useNavigate } from 'react-router'
import React, { FC } from 'react'
import Loader from 'core/components/common/Loader'
import { ApplicationHeader } from 'lauria/components/applications/ApplicationHeader'
import { useLayoutApplication } from 'lauria/hooks/useLayoutApplication'
import { DarkModeProvider } from 'core/contexts/DarkThemeContext'
import { ReactComponent as LogoLauria } from 'core/assets/images/general/logoLauria.svg'
import { DarkThemeToggle } from 'core/components/layout/DarkThemeToggle'
import { CustomToastContainer } from 'core/components/common/Toast'
import { BACKOFFICE_PATH } from 'core/constants/routePath'

export const LayoutApplication: FC = () => {
  const navigate = useNavigate()
  const { application, isLoading } = useLayoutApplication()
  const location = useLocation()
  const goHome = () => {
    if (location.pathname.startsWith(BACKOFFICE_PATH)) {
      navigate(generatePath(BACKOFFICE_PATH))
    } else {
      navigate(generatePath('/'))
    }
  }

  return (
    <DarkModeProvider>
      <div className="layout-container">
        {application && !isLoading ? (
          <>
            <div className="layout-header">
              <div className="flex-shrink-0">
                <LogoLauria className="logo-lauria" onClick={goHome} />
              </div>
              <div className="hidden md:flex items-center">
                <ApplicationHeader application={application} />
              </div>
              <div className="flex-shrink-0 pr-[30px] ">
                <DarkThemeToggle />
              </div>
            </div>
            <Outlet />
          </>
        ) : (
          <div className="w-full h-full flex items-center justify-center">
            <Loader pageCenter />
          </div>
        )}
        <CustomToastContainer />
      </div>
    </DarkModeProvider>
  )
}
