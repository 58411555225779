import ModalWrapper from 'core/components/common/ModalWrapper'
import { FormApplicationModalContent } from 'lauria/components/applications/FormApplicationModalContent'
import { FC, SetStateAction } from 'react'
import { ModalHeader } from 'core/components/common/ModalHeader'
import { useTranslation } from 'react-i18next'
import { ModalBody } from 'core/components/common/ModalBody'
import { IApplication } from 'core/interfaces/Application'

export const FormApplicationModal: FC<{
  open: boolean
  setOpen: (value: SetStateAction<boolean>) => void
  type?: 'add' | 'edit'
  application?: IApplication
}> = ({ open, setOpen, type = 'add', application }) => {
  const { t } = useTranslation()

  const hide = (event: any) => {
    event.preventDefault()
    setOpen(false)
  }

  return (
    <ModalWrapper
      classNames={{ modal: 'modal modal-application' }}
      onClose={() => setOpen(false)}
      open={open}
      showCloseIcon={false}
    >
      <ModalHeader>
        {type === 'add'
          ? t('services.newAppModalTitle')
          : t('applications.form.edit.title')}
      </ModalHeader>
      <ModalBody>
        <FormApplicationModalContent
          cancelAction={hide}
          type={type}
          application={application}
          setModalOpen={setOpen}
        />
      </ModalBody>
    </ModalWrapper>
  )
}
