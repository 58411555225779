import { useFieldArray, useForm } from 'react-hook-form'
import { IApplicationPromptVariable } from 'core/interfaces/Application'
import {
  addMessage,
  resetConversation,
} from 'iastart/store/slices/conversationSlice'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { IDocument } from 'core/interfaces/Document'
import { useAskMutation } from 'core/api/ask'
import { IAskBody } from 'core/interfaces/Ask'
import { useProcessFile } from 'core/hooks/useProcessFile'
import { IMessage, UserType } from 'core/interfaces/Conversation'

export type PromptTestFormData = {
  fields: { name: string; value: string }[]
}
export const usePromptVariables = (
  application: IApplicationPromptVariable,
  setIsSending: React.Dispatch<React.SetStateAction<boolean>>,
  documents: IDocument[]
) => {
  const dispatch = useDispatch()
  const { convertToBase64 } = useProcessFile()
  const [ask, { isLoading: isSending }] = useAskMutation()
  useEffect(() => {
    setIsSending(isSending)
  }, [isSending])

  const variables = application.promptVariables
  const { control, handleSubmit, register } = useForm<PromptTestFormData>({
    defaultValues: {
      fields: variables.map((field) => ({ name: field, value: '' })),
    },
  })
  const { fields } = useFieldArray({
    control,
    name: 'fields',
  })

  const onSubmit = async (data: PromptTestFormData) => {
    const body: IAskBody = {
      application: application.nomTechnique,
      params: {},
      documents: [],
    }
    data.fields.forEach((field) => {
      body.params[field.name] = field.value
    })
    body.documents = (await Promise.all(
      documents.map(async (doc) => {
        if (doc.file) {
          const base64 = await convertToBase64(doc?.file)
          return 'data:' + doc.mimeType + ';base64,' + base64
        }
      })
    )) as string[]
    ask(body)
      .unwrap()
      .then((response) => {
        dispatch(resetConversation({ application: application.nomTechnique }))
        const iaMessage: IMessage = {
          type: UserType.ASSISTANT,
          timestamp: Date.now(),
          content: response.answer,
          id: response.message_id,
        }
        dispatch(
          addMessage({
            application: application.nomTechnique,
            message: iaMessage,
            guid: response.guid,
          })
        )
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return {
    variables,
    fields,
    handleSubmit,
    register,
    onSubmit,
  }
}
