import React from 'react'
import { IPrompt } from 'core/interfaces/Prompt'
import { useTranslation } from 'react-i18next'
import { PromptContentCard } from 'lauria/components/prompts/PromptContentCard'
import { useGetAllModelesQuery } from 'lauria/api/referentielModeles'
import { TemperatureSlider } from 'lauria/components/common/TemperatureSlider'
import Loader from 'core/components/common/Loader'
import { NbDocIcon } from 'lauria/components/common/NbDocIcon'
import { DocumentCard } from 'core/components/documents/DocumentCard'
import { generatePath, useNavigate } from 'react-router'
import { ROUTE_PATHS } from 'core/constants/routePath'
import { useSelector } from 'react-redux'
import { promptLayout } from 'lauria/store/slices/promptLayout'

export const PromptContent: React.FC<{
  prompt: IPrompt
}> = ({ prompt }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { application } = useSelector(promptLayout)
  const { data: modeles, isLoading } = useGetAllModelesQuery()
  const defaultModele = modeles?.find((m) => m.defaut)?.label ?? ''
  const modele =
    modeles?.find((m) => m.nom === prompt.modele)?.label ?? defaultModele
  const handleClick = () => {
    navigate(
      generatePath(ROUTE_PATHS.PROMPT_EDIT, {
        idApplication: application?.id,
        idPrompt: prompt?.id,
      })
    )
  }

  if (isLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader pageCenter />
      </div>
    )
  }

  return (
    <>
      <div className="content-prompt w-full">
        <div className="content-prompt-fields">
          <div className="flex flex-row items-center leading-8">
            <div className="label">{t('prompts.etat')}</div>
            <div>
              <span className={`state ${prompt.actif ? 'actif' : 'inactif'}`}>
                {prompt.actif ? t('prompts.actif') : t('prompts.inactif')}
              </span>
            </div>
          </div>
          <div className="flex flex-row items-center leading-8">
            <div className="label">{t('prompts.form.modeleDispoLabel')}</div>
            <div>{modele}</div>
          </div>
          <div className="flex flex-row items-center leading-8">
            <div className="label">{t('prompts.form.temperatureLabel')}</div>
            <div>
              <TemperatureSlider value={prompt.temperature ?? 0.5} />
            </div>
          </div>
          {prompt.documents && prompt.documents?.length > 0 && (
            <div className="prompt-documents">
              <div className="flex flex-row items-center">
                <NbDocIcon nbDoc={prompt.documents?.length} />
                <span className="font-semibold ml-[10px]">
                  {t('prompts.documents.documentsAttached')}
                </span>
              </div>
              <div className="mt-[10px]">
                {prompt.documents.map((doc, index) => (
                  <DocumentCard filename={doc.nomDocument} key={index} />
                ))}
              </div>
            </div>
          )}
          <div className="flex flex-row pt-30px w-full">
            <div className="w-full">
              <PromptContentCard prompt={prompt} />
            </div>
          </div>
          <button
            className="btn default mt-[30px] w-full md:w-[350px] md:rounded-[5px] rounded-[0] md:h-[50px] h-[60px]"
            onClick={handleClick}
          >
            {t('prompts.edit')}
          </button>
        </div>
      </div>
    </>
  )
}
