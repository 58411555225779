import { useTranslation } from 'react-i18next'
import React from 'react'
import {
  ReactComponent as RefreshIcon,
  ReactComponent as ReloadIcon,
} from 'core/assets/images/icones/refreshIcon.svg'
import { usePromptTest } from 'lauria/hooks/usePromptTest'
import { UseFormGetValues } from 'react-hook-form'
import { PromptForm } from 'core/interfaces/Prompt'
import { IModele } from 'core/interfaces/Modele'
import { PromptTestModele } from 'lauria/components/prompts/PromptTestModele'
import { PromptTestResult } from 'lauria/components/prompts/PromptTestResult'

export const PromptTest: React.FC<{
  getValues: UseFormGetValues<PromptForm>
  assistedMode: boolean
  modeles: IModele[] | undefined
}> = ({ getValues, assistedMode, modeles }) => {
  const { t } = useTranslation()
  const {
    fields,
    handleSubmit,
    register,
    onSubmit,
    isLoading,
    answers,
    getFormTestValues,
    modele1Field,
    refreshVars,
  } = usePromptTest(getValues, assistedMode)

  return (
    <div className="prompt-test-wrapper">
      <div className="flex flex-col w-full">
        <div className="flex flex-row items-center justify-between w-full">
          <div
            className="reload-conversation w-[240px] mx-auto"
            onClick={() => refreshVars()}
          >
            <ReloadIcon className="mr-[10px]" />
            {t('prompts.form.refresh')}
          </div>
        </div>
        <div className="prompt-test-fields-wrapper">
          <form onSubmit={handleSubmit(onSubmit)} className="w-full">
            <div className="flex flex-row w-full">
              <table className="w-2/3">
                <tbody>
                  <tr className="h-[40px]">
                    <td>{t('prompts.form.variable')}</td>
                    <td className="pl-[20px]">{t('prompts.form.content')}</td>
                  </tr>
                  {fields.map((field, index) => (
                    <tr key={index} className="h-[50px]">
                      <td>
                        <div className="test-var">{field.name}</div>
                      </td>
                      <td>
                        <input
                          {...register(`fields.${index}.value`)}
                          className="form-input ml-[20px] h-[40px] w-[200px]"
                          type="text"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {modeles && (
                <PromptTestModele
                  register={register}
                  modeles={modeles}
                  getFormTestValues={getFormTestValues}
                  modele1Field={modele1Field}
                />
              )}
            </div>
            <input
              type="submit"
              className="btn-launch-test"
              value={t('prompts.form.launchTest')}
            />
          </form>
        </div>
      </div>
      <PromptTestResult
        answers={answers}
        isLoading={isLoading}
        modeles={modeles}
      />
    </div>
  )
}
