import { useTranslation } from 'react-i18next'
import { KpiPromptCardList } from 'lauria/components/kpi/KpiPromptCardList'
import React, { useContext } from 'react'
import { KpiPromptContext } from 'lauria/contexts/KpiPromptContext'
import { KpiChart } from 'lauria/components/kpi/KpiChart'
import { usePromptKpi } from 'lauria/hooks/usePromptKpi'

export const KpiContent: React.FC<{ isLoading: boolean }> = ({ isLoading }) => {
  const { t } = useTranslation()
  const { activeMetric, kpis, valueType, setValueType } =
    useContext(KpiPromptContext)
  const { checkIfEmpty } = usePromptKpi()
  const isEmpty = checkIfEmpty(kpis)

  return (
    <div className="kpi-content">
      <div>
        <div className="flex flex-row items-center mb-[15px]">
          <div className="kpi-content-title">{t(activeMetric.title)}</div>
          <select
            className="w-[120px] form-input h-[30px] ml-[15px] cursor-pointer"
            value={valueType}
            onChange={(e) =>
              setValueType(e.target.value as 'TOTAL' | 'AVERAGE')
            }
          >
            <option value={'TOTAL'}>{t('prompts.kpi.value.total')}</option>
            <option value={'AVERAGE'}>{t('prompts.kpi.value.average')}</option>
          </select>
        </div>
        <div className="kpi-content-trait"></div>
        {isEmpty ? (
          <div className="mt-[20px]">{t('prompts.kpi.empty')}</div>
        ) : (
          <>
            <KpiChart isLoading={isLoading} />
            <KpiPromptCardList />
          </>
        )}
      </div>
    </div>
  )
}
