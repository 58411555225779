import { useDarkTheme } from 'core/hooks/useDarkTheme'
import { useTranslation } from 'react-i18next'
import { ReactComponent as SwitchThemeIcon } from 'core/assets/images/general/switchThemeIcon.svg'

export const DarkThemeToggle = () => {
  const { isDarkTheme, setUserTheme } = useDarkTheme()
  const { t } = useTranslation()
  const toggleTheme = () => {
    setUserTheme(isDarkTheme ? 'light' : 'dark')
  }

  return (
    <div onClick={toggleTheme} className="dark-theme-wrapper">
      <span className="toggle-button-dt">{t('layout.toggleThemeButton')}</span>
      <div className="toggle-button-dt-icon">
        <SwitchThemeIcon />
      </div>
    </div>
  )
}
