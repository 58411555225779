import { UseFormGetValues } from 'react-hook-form'
import { PromptForm } from 'core/interfaces/Prompt'
import { useEffect, useState } from 'react'
import { IConverseBody } from 'iastart/interfaces/Conversation'
import { ROUTE_PATHS } from 'core/constants/routePath'
import { useConverseMutation } from 'iastart/api/conversation'
import { PROMPT_PLUS_APP } from 'core/constants/general'
import { useNavigate } from 'react-router'
import { IMessage, UserType } from 'core/interfaces/Conversation'

export const useBoostPrompt = (
  getFormPromptValues: UseFormGetValues<PromptForm>,
  assistedMode: boolean
) => {
  const navigate = useNavigate()
  const [converse, { isLoading }] = useConverseMutation()
  const [shouldConverse, setShouldConverse] = useState(false)
  const [conversation, setConversation] = useState<{
    guid: string | null
    messages: IMessage[]
  }>({ guid: null, messages: [] })
  const getPromptContent = () => {
    let text = ''
    if (assistedMode) {
      const assistedValues = [
        getFormPromptValues('role'),
        getFormPromptValues('tache'),
        getFormPromptValues('format'),
        getFormPromptValues('contexte'),
      ]
      text = assistedValues.filter(Boolean).join(' ')
    } else {
      text = getFormPromptValues('prompt') || ''
    }
    return text
  }
  useEffect(() => {
    if (conversation.guid === null) {
      handleConverse(getPromptContent())
    }
  }, [])
  const handleResetConversation = () => {
    setConversation({ guid: null, messages: [] })
    setShouldConverse(true)
  }
  useEffect(() => {
    if (shouldConverse) {
      handleConverse(getPromptContent())
      setShouldConverse(false)
    }
  }, [shouldConverse])
  const handleConverse = (message: string) => {
    const body: IConverseBody = {
      application: PROMPT_PLUS_APP,
      params: {
        consigne: JSON.stringify(message),
      },
    }
    if (conversation.guid !== null) {
      body.guid = conversation.guid
    } else {
      body.guid = null
    }
    converse(body)
      .unwrap()
      .then((response) => {
        const messages = []
        if (conversation.guid !== null) {
          messages.push({
            type: UserType.USER,
            timestamp: Date.now(),
            content: message,
          })
        }

        messages.push({
          type: UserType.ASSISTANT,
          timestamp: Date.now(),
          content: response.answer,
          id: response.message_id,
        })
        setConversation({
          guid: response.guid,
          messages: [...conversation.messages, ...messages],
        })
      })
      .catch((error) => {
        console.error(error)
        navigate(ROUTE_PATHS.ERROR)
      })
  }

  return {
    handleConverse,
    isLoading,
    conversation,
    handleResetConversation,
  }
}
