import { ReactComponent as Error500Logo } from 'core/assets/images/general/error500.svg'
import { ReactComponent as Error500LogoDark } from 'core/assets/images/general/error500Dark.svg'
import { useTranslation } from 'react-i18next'
import React, { useContext } from 'react'
import { DarkThemeContext } from 'core/contexts/DarkThemeContext'

export const Error500 = () => {
  const { t } = useTranslation()
  const { isDarkTheme } = useContext(DarkThemeContext)

  return (
    <div className="w-full flex flex-col items-center h-full pt-60px">
      {isDarkTheme ? <Error500LogoDark /> : <Error500Logo />}
      <div className="mt-[20px] text-red-1300 text-2xl dark:text-red-1350">
        {t('error500')}
      </div>
    </div>
  )
}
