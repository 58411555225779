import { KpiPromptCard } from 'lauria/components/kpi/KpiPromptCard'
import React, { useContext, useState } from 'react'
import { ReactComponent as EyeOpened } from 'core/assets/images/icones/openedEye.svg'
import { ReactComponent as EyeClosed } from 'core/assets/images/icones/closedEye.svg'
import { useTranslation } from 'react-i18next'
import { KpiPromptContext } from 'lauria/contexts/KpiPromptContext'

export const KpiPromptCardList = () => {
  const { t } = useTranslation()
  const { promptsList, selectedPrompts, setSelectedPrompts } =
    useContext(KpiPromptContext)
  if (!promptsList) {
    return null
  }
  const [errorId, setErrorId] = useState<null | number>(null)
  const cardClasses = ['first', 'second', 'third']
  const [visibility, setVisibility] = useState({
    inactive: true,
    deleted: true,
  })
  const activePrompts = promptsList?.filter(
    (prompt) => prompt.actif && !prompt.suppression
  )
  const inactivePrompts = promptsList?.filter(
    (prompt) => !prompt.actif && !prompt.suppression
  )
  const deletedPrompts = promptsList?.filter((prompt) => prompt.suppression)
  const selectPrompt = (id: number) => {
    const index = selectedPrompts.indexOf(id)
    setErrorId(null)
    if (index !== -1) {
      setSelectedPrompts((prevSelected) =>
        prevSelected.filter((promptId) => promptId !== id)
      )
    } else {
      if (selectedPrompts.length >= 3) {
        setErrorId(id)
      } else {
        setSelectedPrompts((prevSelected) => [...prevSelected, id])
      }
    }
  }
  const renderSection = (
    title: string,
    prompts: any[],
    classname: string,
    isVisible?: boolean,
    toggleVisibility?: () => void
  ) => {
    if (!prompts.length) return null

    return (
      <>
        {toggleVisibility && (
          <button
            onClick={toggleVisibility}
            className="my-4 text-blue-1350 hover:underline"
          >
            <div className="flex flex-row items-center gap-4">
              {isVisible ? (
                <>
                  <EyeClosed />
                  {t(`${title}.hide`)}
                </>
              ) : (
                <>
                  <EyeOpened />
                  {t(`${title}.show`)}
                </>
              )}
            </div>
          </button>
        )}
        {isVisible !== false && (
          <div className="kpi-prompt-card-list-wrapper">
            {prompts.map((prompt) => {
              const index = selectedPrompts.indexOf(prompt.id)
              const isSelected = index !== -1
              const cardClass = isSelected ? cardClasses[index] : ''
              return (
                <KpiPromptCard
                  key={prompt.id}
                  prompt={prompt}
                  classname={`${classname} ${cardClass}`}
                  handleClick={() => selectPrompt(prompt.id)}
                  selected={isSelected}
                  error={errorId === prompt.id}
                />
              )
            })}
          </div>
        )}
      </>
    )
  }

  return (
    <div className="kpi-prompt-card-list">
      <span className="h-[30px] font-medium">{t('prompts.kpi.active')}</span>
      {renderSection('prompts.kpi.active', activePrompts, 'active')}
      {renderSection(
        'prompts.kpi.inactive',
        inactivePrompts,
        'inactive',
        visibility.inactive,
        () =>
          setVisibility((prev) => ({
            ...prev,
            inactive: !prev.inactive,
          }))
      )}
      {renderSection(
        'prompts.kpi.deleted',
        deletedPrompts,
        'deleted',
        visibility.deleted,
        () =>
          setVisibility((prev) => ({
            ...prev,
            deleted: !prev.deleted,
          }))
      )}
    </div>
  )
}
