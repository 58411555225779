import { IDocument } from 'core/interfaces/Document'
import { ReactComponent as DocumentIcon } from 'core/assets/images/documents/uploadDocument.svg'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FILE_EXTENSION_GROUPS, MAX_FILE_SIZE } from 'core/constants/general'
import { DocumentList } from 'iastart/components/DocumentList'

interface DocumentsProps {
  documents: IDocument[]
  setDocuments: React.Dispatch<React.SetStateAction<IDocument[]>>
}

export const Documents: React.FC<DocumentsProps> = ({
  documents,
  setDocuments,
}) => {
  const { t } = useTranslation()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const allowedExtensions = Object.values(FILE_EXTENSION_GROUPS).flat()
  const handleFileChange = (event: any) => {
    if (documents.length >= 6) {
      return
    }
    const file = event.target.files?.[0]
    if (!file) return
    const fileExtension = file.name.split('.').pop()?.toLowerCase()
    if (!allowedExtensions.includes(fileExtension || '')) {
      setErrorMessage(t('iastart.documents.invalidExtension'))
      return
    }
    if (file.size > MAX_FILE_SIZE) {
      setErrorMessage(t('iastart.documents.fileTooLarge'))
      return
    }
    const filesName = documents.map((file) => file.nomDocument)
    if (!filesName.includes(file.name)) {
      setDocuments([
        ...documents,
        {
          nomDocument: file.name,
          mimeType: file.type,
          file: file,
        },
      ])
    }
    setErrorMessage(null)
  }

  return (
    <div
      className={`documents-wrapper w-3/4 ${documents.length > 0 ? 'with-bg' : ''}`}
    >
      <div className="w-1/4">
        <div
          className={`card-doc ${errorMessage ? 'card-error' : ''} ${documents.length >= 6 ? 'card-disabled' : 'card-enabled with-hover'}`}
        >
          <label htmlFor="file-upload" className="upload-doc-label">
            <div className="mx-[10px]">
              <DocumentIcon />
            </div>
            <div>{t('iastart.documents.attach')}</div>
          </label>
          {documents.length < 6 && (
            <input
              id="file-upload"
              type="file"
              onChange={handleFileChange}
              className="hidden"
            />
          )}
        </div>
        {errorMessage && (
          <div className="text-red-1300 mt-1 text-[11px]">{errorMessage}</div>
        )}
      </div>
      <div className="w-3/4">
        <DocumentList
          documents={documents}
          setDocuments={setDocuments}
          withDelete
        />
      </div>
    </div>
  )
}
