import React from 'react'
import { ReactComponent as AddIcon } from 'core/assets/images/icones/addIconePlain.svg'
import { useTranslation } from 'react-i18next'

export const PromptListHeader: React.FC<{
  createPrompt: () => void
  setShowInactive: React.Dispatch<React.SetStateAction<boolean>>
  showInactive: boolean
}> = ({ createPrompt, setShowInactive, showInactive }) => {
  const { t } = useTranslation()

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setShowInactive(event.target.value === 'inactif')
  }
  return (
    <div className="prompt-list-header">
      <div className="flex flex-row items-center">
        <div>{t('prompts.prompts')}</div>
        <div className="uppercase font-light">
          <select
            id="status"
            className="form-input ml-[80px] uppercase focus:outline-none w-[200px] hover:cursor-pointer"
            onChange={handleChange}
            value={showInactive ? 'inactif' : 'actif'}
          >
            <option value="actif">{t('prompts.actif')}</option>
            <option value="inactif">{t('prompts.inactif')}</option>
          </select>
        </div>
        <div
          className="btn-without-bg flex flex-row items-center ml-auto"
          onClick={createPrompt}
        >
          <AddIcon className="text-white dark:text-black" />
          <span className="ml-10px">{t('prompts.newPrompt')}</span>
        </div>
      </div>
      <div className="trait"></div>
    </div>
  )
}
