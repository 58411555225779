import { IPrompt } from 'core/interfaces/Prompt'
import Card from 'core/components/common/Card'
import React from 'react'
import { ReactComponent as PromptIconBanner } from 'lauria/assets/images/prompts/promptIconBanner.svg'
import { ReactComponent as InactivePromptIconBanner } from 'lauria/assets/images/prompts/inactiveBannerIconPrompt.svg'

export const PromptContentCard: React.FC<{ prompt: IPrompt }> = ({
  prompt,
}) => {
  return (
    <Card className="prompt-card w-full" withHoverStyle={false}>
      <div className="card-prompt-header-wrapper pl-[25px] pr-[35px]">
        <div className="card-prompt-header-content py-[20px]">
          {prompt.actif ? <PromptIconBanner /> : <InactivePromptIconBanner />}
          <div className="ml-[20px] uppercase font-medium">
            <span>{prompt.nomPrompt}</span>
          </div>
        </div>
      </div>
      <div className="prompt-card-prompt pl-[25px]">{prompt.prompt}</div>
    </Card>
  )
}
