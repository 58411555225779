import { FILE_EXTENSION_GROUPS } from 'core/constants/general'
import { ReactComponent as DocumentIcon } from 'core/assets/images/documents/documentIcon.svg'
import { ReactComponent as PptIcon } from 'core/assets/images/documents/pptIcon.svg'
import { ReactComponent as ImageIcon } from 'core/assets/images/documents/imageIcon.svg'
import React from 'react'

export const DocumentIconComponent: React.FC<{ extension: string }> = ({
  extension,
}) => {
  if (FILE_EXTENSION_GROUPS['doc'].includes(extension)) {
    return <DocumentIcon className="text-blue-1375" />
  } else if (FILE_EXTENSION_GROUPS['pdf'].includes(extension)) {
    return <DocumentIcon className="text-red-1400" />
  } else if (FILE_EXTENSION_GROUPS['excel'].includes(extension)) {
    return <DocumentIcon className="text-green-1300" />
  } else if (FILE_EXTENSION_GROUPS['image'].includes(extension)) {
    return <ImageIcon />
  } else if (FILE_EXTENSION_GROUPS['ppt'].includes(extension)) {
    return <PptIcon />
  }

  return null
}
