import React from 'react'
import { ReactComponent as FillStar } from 'core/assets/images/icones/fillStar.svg'
import { ReactComponent as HalfStar } from 'core/assets/images/icones/halfStar.svg'
import { ReactComponent as OutlineStar } from 'core/assets/images/icones/outlineStar.svg'

export const DisplayStarRating: React.FC<{
  note: number
  className?: string
}> = ({ note, className }) => {
  const stars = Array.from({ length: 5 }, (_, index) => {
    const starIndex = index + 1
    if (starIndex <= note) {
      return <FillStar key={index} className={className ?? ''} />
    } else if (starIndex <= note + 0.5) {
      return <HalfStar key={index} className={className ?? ''} />
    } else {
      return <OutlineStar key={index} className={className ?? ''} />
    }
  })
  return <div className="flex">{stars}</div>
}
