import React from 'react'
import { Outlet } from 'react-router'
import { LayoutSubHeader } from 'iastart/components/layout/LayoutSubHeader'
import { Menu } from 'iastart/components/layout/Menu'
import { CurrentHeaderProvider } from 'iastart/contexts/CurrentHeaderContext'

export const LayoutApp = () => {
  return (
    <CurrentHeaderProvider>
      <div className="w-full h-full">
        <LayoutSubHeader />
        <div className="flex flex-row h-[calc(100vh-160px)]">
          <Menu />
          <div className="flex-grow overflow-y-auto">
            <Outlet />
          </div>
        </div>
      </div>
    </CurrentHeaderProvider>
  )
}
