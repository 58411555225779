import { PromptTest } from 'lauria/components/prompts/PromptTest'
import React, { useState } from 'react'
import { UseFormGetValues } from 'react-hook-form'
import { PromptForm } from 'core/interfaces/Prompt'
import { IModele } from 'core/interfaces/Modele'
import { PromptBoost } from 'lauria/components/prompts/PromptBoost'
import { Trans, useTranslation } from 'react-i18next'
import { ReactComponent as TestIcon } from 'core/assets/images/icones/test.svg'
import { ReactComponent as BoostIcon } from 'core/assets/images/icones/boost.svg'

export const PromptFormSide: React.FC<{
  getValues: UseFormGetValues<PromptForm>
  assistedMode: boolean
  modeles: IModele[] | undefined
}> = ({ getValues, assistedMode, modeles }) => {
  const { t } = useTranslation()
  const options = [
    {
      label: t('prompts.form.boostPrompt'),
      key: 'boost',
      icon: <BoostIcon />,
      component: (
        <PromptBoost getValues={getValues} assistedMode={assistedMode} />
      ),
    },
    {
      label: t('prompts.form.testPrompt'),
      key: 'test',
      icon: <TestIcon />,
      component: (
        <PromptTest
          getValues={getValues}
          assistedMode={assistedMode}
          modeles={modeles}
        />
      ),
    },
  ]
  const [selectedTab, setSelectedTab] = useState<string | null>(null)

  return (
    <div className="prompt-form-side">
      <div className="flex flex-row items-center justify-center w-full gap-4 my-[20px]">
        {options.map(({ label, key, icon }) => (
          <button
            key={key}
            onClick={() => setSelectedTab(key)}
            className={`prompt-action-btn ${
              selectedTab === key ? 'active' : 'inactive'
            }`}
          >
            {icon}
            <div className="ml-[10px]">{label}</div>
          </button>
        ))}
      </div>

      {selectedTab === null && (
        <div className="action-infos">
          <Trans
            i18nKey="prompts.form.selectAction"
            components={{ strong: <strong className="font-medium" /> }}
          />
        </div>
      )}

      <div className="w-full h-[calc(100vh-291px)]">
        {selectedTab &&
          options.find(({ key }) => key === selectedTab)?.component}
      </div>
    </div>
  )
}
