import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { useGetApplicationPromptVariablesQuery } from 'core/api/application'
import { skipToken } from '@reduxjs/toolkit/query'
import { useContext, useEffect } from 'react'
import { ROUTE_PATHS } from 'core/constants/routePath'
import { CurrentHeaderContext } from 'iastart/contexts/CurrentHeaderContext'
import { getIconeInfo } from 'core/utils/service'

export const useAppIastart = () => {
  const { app } = useParams()
  const navigate = useNavigate()
  const { setHeader } = useContext(CurrentHeaderContext)

  const { data: application, isLoading: isApplicationLoading } =
    useGetApplicationPromptVariablesQuery(
      app ? { applicationName: app } : skipToken
    )
  useEffect(() => {
    if (isApplicationLoading) return

    if (!application || !application.id) {
      navigate(ROUTE_PATHS.ERROR)
      return
    }

    const { imageSmall, imageSmallDark } = getIconeInfo(application.service)

    setHeader({
      svgPath: imageSmall,
      svgPathDark: imageSmallDark,
      accroche: application.nomApplication,
      column: false,
    })
  }, [application, isApplicationLoading])

  return {
    isApplicationLoading,
    application: application ?? null,
  }
}
