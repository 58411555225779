import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IApplication } from 'core/interfaces/Application'
import { IPrompt } from 'core/interfaces/Prompt'

export type PromptLayoutRootState = {
  promptLayout: PromptLayoutState
}

interface PromptLayoutState {
  application: IApplication | null
  prompts: IPrompt[]
}

const initialState: PromptLayoutState = {
  application: null,
  prompts: [],
}

const promptLayoutSlice = createSlice({
  name: 'promptLayout',
  initialState,
  reducers: {
    setApplication(state, action: PayloadAction<IApplication | null>) {
      state.application = action.payload
    },
    setPrompts(state, action: PayloadAction<IPrompt[]>) {
      state.prompts = action.payload
    },
  },
})

export const promptLayout = (state: PromptLayoutRootState) => state.promptLayout

export const { setApplication, setPrompts } = promptLayoutSlice.actions

export default promptLayoutSlice
