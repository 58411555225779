import React, { useRef, useState } from 'react'
import { IAMessage } from 'core/components/conversation/IAMessage'
import { getMessages } from 'iastart/store/slices/conversationSlice'
import { useTranslation } from 'react-i18next'
import { VariablesInput } from 'iastart/components/VariablesInput'
import { useSelector } from 'react-redux'
import { RootState } from 'core/store'
import { Documents } from 'iastart/components/Documents'
import { IApplicationPromptVariable } from 'core/interfaces/Application'
import { IDocument } from 'core/interfaces/Document'
import { IMessage, UserType } from 'core/interfaces/Conversation'

export const PromptTest: React.FC<{
  application: IApplicationPromptVariable
}> = ({ application }) => {
  const { t } = useTranslation()
  const messagesContainerRef = useRef<HTMLDivElement | null>(null)
  const messages: IMessage[] = useSelector((state: RootState) =>
    getMessages(state, application.nomTechnique)
  )
  const [documents, setDocuments] = useState<IDocument[]>([])
  const [isSending, setIsSending] = useState<boolean>(false)

  return (
    <div className="flex items-center w-2/3 mx-auto h-full">
      <div className="conversation">
        <div
          ref={messagesContainerRef}
          className="w-full flex-grow overflow-auto"
        >
          <div className="messages-wrapper">
            <IAMessage
              message={{
                content: t('iastart.application.accroche', {
                  application: application.nomApplication,
                }),
                type: UserType.ASSISTANT,
                timestamp: Date.now(),
              }}
              application={application.nomApplication}
              first
            />
            {isSending && (
              <IAMessage
                message={{
                  content: '',
                  type: UserType.ASSISTANT,
                  timestamp: Date.now(),
                }}
                application={application.nomApplication}
                isLoading
              />
            )}
            {!isSending &&
              messages.map((message, index) => {
                return (
                  <IAMessage
                    key={index}
                    message={message}
                    application={application.nomTechnique}
                  />
                )
              })}
          </div>
        </div>
        <div className="message-input-wrapper">
          <div className="flex flex-col my-[10px]">
            <Documents documents={documents} setDocuments={setDocuments} />
            <VariablesInput
              application={application}
              setIsSending={setIsSending}
              documents={documents}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
