export enum UserType {
  USER = 'user',
  ASSISTANT = 'assistant',
}

export interface IMessage {
  id?: number
  type: UserType
  timestamp: number
  content: string
  feedback?: number
}

export interface IConversation {
  guid: string
  messages: IMessage[]
}

export interface IApplicationConversation {
  applicationName: string
  accroche: string
  placeholder: string
}
