import { IDocument } from 'core/interfaces/Document'
import React, { SetStateAction } from 'react'
import { DocumentCard } from 'core/components/documents/DocumentCard'

export const DocumentList: React.FC<{
  documents: IDocument[]
  withDelete?: boolean
  setDocuments?: (value: SetStateAction<IDocument[]>) => void
}> = ({ documents, withDelete = false, setDocuments = () => {} }) => {
  const deleteDoc = (indexToDelete: number) => {
    if (withDelete) {
      const updatedDocuments = documents.filter(
        (doc, index) => index !== indexToDelete
      )
      setDocuments(updatedDocuments)
    }
  }

  if (documents.length === 0) {
    return null
  }
  return (
    <div className="prompt-document-list-content">
      {documents.map((doc, index) => (
        <DocumentCard
          filename={doc.nomDocument}
          withDelete={withDelete}
          key={index}
          deleteDoc={() => deleteDoc(index)}
        />
      ))}
    </div>
  )
}
