import { FieldLabel } from 'core/components/common/FieldLabel'
import React, { MutableRefObject } from 'react'
import { ControllerRenderProps } from 'react-hook-form'
import { PromptForm } from 'core/interfaces/Prompt'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ConsigneIcon } from 'lauria/assets/images/prompts/consigneIcon.svg'

export const PromptFormFieldsExpert: React.FC<{
  promptField: ControllerRenderProps<PromptForm, 'prompt'>
  promptRef: MutableRefObject<HTMLTextAreaElement | null>
  addTextToTextarea: (
    event: React.MouseEvent,
    text: string,
    ref: MutableRefObject<any>,
    fieldName: string
  ) => void
  errors: any
}> = ({ promptField, promptRef, addTextToTextarea, errors }) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col">
      <FieldLabel
        className="h-8 md:h-12 md:align-top md:py-3"
        label={t('prompts.form.promptLabel')}
        required
      />
      <div className="h-full flex md:flex-row flex-col w-full">
        <div className="flex flex-col flex-grow">
          <textarea
            placeholder={t('global.form.textarea.placeholder')}
            className={`form-textarea h-[150px] w-full md:h-[300px] ${errors.prompt ? 'error' : ''}`}
            {...promptField}
            ref={(e) => {
              promptField.ref(e)
              // eslint-disable-next-line no-param-reassign
              promptRef.current = e
            }}
          />
          {errors.prompt && (
            <p className="form-error mt-10px">
              {errors.prompt.type === 'required'
                ? t('global.form.error.mandatory')
                : errors.prompt.message}
            </p>
          )}
          <div className="flex flex-col md:flex-row md:justify-start mt-15px">
            <button
              className="button-consigne"
              onClick={(event) =>
                addTextToTextarea(event, '{consigne}', promptRef, 'prompt')
              }
            >
              <ConsigneIcon />
              <span className="flex-grow text-center">
                {t('prompts.form.button.consigne')}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
