import { FieldLabel } from 'core/components/common/FieldLabel'
import { FormInput } from 'core/components/common/FormInput'
import React from 'react'
import { useEditTag } from 'lauria/hooks/useEditTag'
import { ITag } from 'core/interfaces/Tag'
import { useTranslation } from 'react-i18next'

export const TagForm: React.FC<{
  setModalOpen?: (value: boolean) => void
  cancelAction?: (event: any) => void
  tag?: ITag | null
}> = ({ setModalOpen, cancelAction, tag }) => {
  const { register, isValid, onSubmit, errors } = useEditTag(setModalOpen, tag)
  const { t } = useTranslation()
  return (
    <form onSubmit={onSubmit} className="w-full">
      <div className="tag-form">
        <div className="flex flex-col">
          <FieldLabel
            className={`h-[45px] ${tag ? 'w-[120px]' : 'w-[90px]'} flex items-center`}
            label={t('tags.edit.intituleLabel')}
            required={!!tag}
          />
          <FormInput
            fieldName="intitule"
            error={errors.intitule}
            register={register}
            wrapperClassName={`w-full ${tag ? 'md:mr-[40px]' : 'md:mr-[60px]'}`}
            inputClassName="w-full form-input"
          />
        </div>
        <div
          className={`text-right self-end w-full md:block ${isValid ? '' : 'hidden'} mt-[30px]`}
        >
          <button
            className="btn cancel hidden text-[14px]"
            onClick={cancelAction}
          >
            {t('global.form.button.cancel')}
          </button>
          <input
            type="submit"
            className="btn default md:ml-30px w-full md:w-[240px] text-[14px]"
            value={
              tag ? t('tags.form.edit.button') : t('tags.form.create.button')
            }
          />
        </div>
      </div>
    </form>
  )
}
