import { PROMPT_PLUS_APP } from 'core/constants/general'
import { useTranslation } from 'react-i18next'
import { useBoostPrompt } from 'lauria/hooks/useBoostPrompt'
import { UseFormGetValues } from 'react-hook-form'
import { PromptForm } from 'core/interfaces/Prompt'
import { ConversationComponent } from 'core/components/conversation/ConversationComponent'
import { ReactComponent as ReloadIcon } from 'core/assets/images/icones/refreshIcon.svg'

export const PromptBoost: React.FC<{
  getValues: UseFormGetValues<PromptForm>
  assistedMode: boolean
}> = ({ getValues, assistedMode }) => {
  const { t } = useTranslation()
  const { handleConverse, handleResetConversation, isLoading, conversation } =
    useBoostPrompt(getValues, assistedMode)
  return (
    <>
      <div
        className="reload-conversation justify-self-center"
        onClick={() => handleResetConversation()}
      >
        <ReloadIcon className="mr-[10px]" />
        {t('prompts.form.restart')}
      </div>
      <div className="flex h-full">
        <ConversationComponent
          application={{
            accroche: '',
            applicationName: PROMPT_PLUS_APP,
            placeholder: t('global.form.input.placeholder'),
          }}
          handleConverse={(msg) => handleConverse(msg)}
          isLoading={isLoading}
          messages={conversation.messages}
          withMessageLabel={false}
          withResetButton={false}
          smallAvatar
        />
      </div>
    </>
  )
}
