import {
  DEFAULT_APP_COLOR,
  DEFAULT_APP_DARK_COLOR,
  DEFAULT_APP_ICONE,
  DEFAULT_APP_ICONE_DARK,
} from 'core/constants/general'
import { IApplication, IApplicationService } from 'core/interfaces/Application'
import { IServiceGroupedApplications } from 'core/interfaces/Service'
import { PERIOD } from 'core/constants/period'
import {
  add,
  intervalToDuration,
  startOfMonth,
  startOfWeek,
  sub,
} from 'date-fns'
import { ITagRole } from 'core/interfaces/User'
import { ROLE_ADMIN, ROLE_DESACTIVE, ROLE_USER } from 'core/constants/role'

export const getIconeInfo = (service: IApplicationService) => {
  let color, colorDark, svgPath, svgPathDark, imageSmall, imageSmallDark
  try {
    const parsedIcon = service && JSON.parse(service?.icone)
    if (parsedIcon) {
      ;({ color, colorDark, imageSmall, imageSmallDark } = parsedIcon)
    }
    svgPath = require(`core/assets/images/logos/${imageSmall}`)
    svgPathDark = require(`core/assets/images/logos/${imageSmallDark}`)
  } catch (error) {
    color = DEFAULT_APP_COLOR
    colorDark = DEFAULT_APP_DARK_COLOR
    svgPath = require(`core/assets/images/logos/${DEFAULT_APP_ICONE}`)
    svgPathDark = require(`core/assets/images/logos/${DEFAULT_APP_ICONE_DARK}`)
    imageSmall = DEFAULT_APP_ICONE
    imageSmallDark = DEFAULT_APP_ICONE_DARK
  }

  return { color, colorDark, svgPath, svgPathDark, imageSmall, imageSmallDark }
}

export const groupByService = (applications: IApplication[]) => {
  return applications.reduce((acc, app) => {
    const serviceName = app.service.nomService
    const serviceIcone = getIconeInfo(app.service)
    const newAcc = { ...acc }
    if (!newAcc[serviceName]) {
      newAcc[serviceName] = {
        service: serviceIcone,
        apps: [],
      }
    }
    newAcc[serviceName].apps.push(app)

    return newAcc
  }, {} as IServiceGroupedApplications)
}

export const getPeriodDates = (
  period: string,
  currentDate: Date
): [Date, Date] => {
  const periodMap = {
    [PERIOD.HEBDO]: [
      startOfWeek(currentDate, { weekStartsOn: 1 }),
      add(startOfWeek(currentDate, { weekStartsOn: 1 }), { days: 6 }),
    ],
    [PERIOD.MENSUELLE]: [
      startOfMonth(currentDate),
      add(startOfMonth(currentDate), { months: 1, days: -1 }),
    ],
    [PERIOD.SEMESTRIELLE]: [add(currentDate, { months: -5 }), currentDate],
    [PERIOD.ANNUELLE]: [sub(currentDate, { months: 12 }), currentDate],
  } as Record<string, [Date, Date]>
  return periodMap[period] ?? [currentDate, currentDate]
}
export const hasAdminRole = (tagRoles: ITagRole[]): boolean =>
  tagRoles.some((tagRole) => tagRole.role.role.toLowerCase() === ROLE_ADMIN)

export const getRole = (tagRoles: ITagRole[]): string => {
  const adminRole = tagRoles.find(
    (tagRole) => tagRole.role.role.toLowerCase() === ROLE_ADMIN
  )
  if (adminRole) {
    return ROLE_ADMIN
  }
  const userRole = tagRoles.find(
    (tagRole) => tagRole.role.role.toLowerCase() === ROLE_USER
  )
  if (userRole) {
    return ROLE_USER
  }
  return ROLE_DESACTIVE
}
export const formatTime = (timeInMs: number): string => {
  const duration = intervalToDuration({ start: 0, end: timeInMs })

  const minutes = duration.minutes || 0
  const seconds = duration.seconds || 0

  const formattedTime = [
    minutes > 0 ? `${minutes} min` : '',
    seconds > 0 ? `${seconds} sec.` : '',
  ].join('')

  return formattedTime || '0sec'
}
