import React, { useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router'
import { DarkThemeContext } from 'core/contexts/DarkThemeContext'
import { ReactComponent as Arrow } from 'core/assets/images/icones/arrow.svg'
import { ROUTE_PATHS } from 'core/constants/routePath'

interface CardProps {
  logo: string
  logoDark: string
  usageKey: string
  isMobile?: boolean
}

export const IaStartCard: React.FC<CardProps> = ({
  logo,
  logoDark,
  usageKey,
  isMobile = false,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isDarkTheme } = useContext(DarkThemeContext)
  const svgPath = require(`core/assets/images/logos/${logo}`)
  const svgPathDark = require(`core/assets/images/logos/${logoDark}`)
  const handleClick = () => {
    navigate(
      generatePath(ROUTE_PATHS.IASTART_CONVERSATION, { usage: usageKey })
    )
  }

  return (
    <div
      className={`card-accueil-wrapper ${isMobile ? 'w-full h-[160px]' : 'min-w-[350px] max-w-[350px] min-h-[450px] group'}`}
      onClick={handleClick}
    >
      <div className="card-accueil">
        <div className="header-wrapper group-hover:dark:bg-gray-1450 group-hover:bg-blue-1100">
          <div
            className={`card-accueil-header ${isMobile ? 'card-accueil-header-mobile' : 'card-accueil-header-general'} group-hover:dark:bg-purple-1700 group-hover:bg-blue-1150`}
          >
            <img
              className={isMobile ? 'h-[60px] ml-15px' : 'mx-auto mt-[60px]'}
              src={isDarkTheme ? svgPathDark : svgPath}
              alt="Icon"
            />
            {isMobile && (
              <span className="title">
                <Trans
                  i18nKey={`iastart.usages.${usageKey}.title`}
                  components={{
                    big: (
                      <span className={isMobile ? 'text-2xl' : 'text-[40px]'} />
                    ),
                  }}
                />
              </span>
            )}
          </div>
        </div>
        <div className="content">
          {!isMobile && (
            <div className="title">
              <Trans
                i18nKey={`iastart.usages.${usageKey}.title`}
                components={{ big: <span className="text-[40px]" /> }}
              />
            </div>
          )}
          <div className={isMobile ? 'text-mobile' : 'text'}>
            {t(`iastart.usages.${usageKey}.${isMobile ? 'mobile' : 'text'}`)}
          </div>
          {!isMobile && (
            <button
              className="btn default discover group-hover:bg-blue-1400 group-hover:text-white group-hover:dark:text-purple-1100 group-hover:dark:bg-purple-1700"
              onClick={handleClick}
            >
              {t('iastart.button')}
              <Arrow className="ml-[20px]" />
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
