import { IUser } from 'core/interfaces/User'
import React, { createContext, ReactNode } from 'react'
import { ROLE_ADMIN } from 'core/constants/role'

interface IUserContextType {
  user: IUser | null
  isAdmin: boolean
  setCurrentUser: (user: IUser | null) => void
}
export const UserContext = createContext<IUserContextType>({
  user: null,
  isAdmin: false,
  setCurrentUser: () => {},
})

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = React.useState<IUser | null>(null)
  const [isAdmin, setIsAdmin] = React.useState<boolean>(false)
  const setCurrentUser = (user: IUser | null) => {
    setUser(user)
    setIsAdmin(
      user?.utilisateurTagRoles.some(
        (tagRole) => tagRole.role.role.toLowerCase() === ROLE_ADMIN
      ) ?? false
    )
  }

  return (
    <UserContext.Provider
      value={{
        user,
        isAdmin,
        setCurrentUser,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
