import { toast } from 'core/components/common/Toast'
import {
  ERROR_DUPLICATE_NOM_APPLICATION,
  ERROR_DUPLICATE_NOM_TECHNIQUE,
} from 'core/constants/error'
import {
  useCreateApplicationMutation,
  useUpdateApplicationMutation,
} from 'core/api/application'
import { useForm } from 'react-hook-form'
import { ApplicationForm } from 'lauria/components/applications/FormApplicationModalContent'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router'
import { ROUTE_PATHS } from 'core/constants/routePath'
import { IApplication } from 'core/interfaces/Application'

export const useCreateApp = (
  selectedTags: { id: number; label: string }[],
  setModalOpen: (value: boolean) => void,
  application?: IApplication
) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const initialValues = {
    intitule: application?.nomApplication || '',
    nomTechnique: application?.nomTechnique || '',
    idService: application?.service.id.toString() || '',
    tags:
      application?.tags && application.tags.length > 0
        ? JSON.stringify(application?.tags)
        : '',
  }
  const defaultResolver = async (data: ApplicationForm) => {
    const errors: any = {}

    if (!data.intitule) {
      errors.intitule = { type: 'required', message: '' }
    }
    if (!data.nomTechnique) {
      errors.nomTechnique = { type: 'required', message: '' }
    }
    if (!data.idService) {
      errors.idService = { type: 'required', message: '' }
    }
    if (!data.tags) {
      errors.tags = { type: 'required', message: '' }
    }

    return {
      values: Object.keys(errors).length ? {} : data,
      errors,
    }
  }
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isValid },
    setValue,
  } = useForm<ApplicationForm>({
    defaultValues: initialValues,
    resolver: defaultResolver,
  })

  const [createApplication, { isLoading: isLoadingCreateApplication }] =
    useCreateApplicationMutation()
  const [updateApplication, { isLoading: isLoadingUpdateApplication }] =
    useUpdateApplicationMutation()

  const onSubmit = handleSubmit((data) => {
    if (application) {
      updateApplication({
        idApplication: application.id,
        idService: parseInt(data.idService),
        nomApplication: data.intitule,
        tags: selectedTags.map((tag) => tag.id),
      })
        .unwrap()
        .then((application) => {
          if (application.id) {
            navigate(
              generatePath(ROUTE_PATHS.PROMPTS, {
                idApplication: application.id,
              })
            )
            toast.success(t('applications.form.edit.success'))
            setModalOpen(false)
          }
        })
        .catch((error) => {
          if (error.status === 409) {
            if (error.data?.detail === ERROR_DUPLICATE_NOM_APPLICATION) {
              setError('intitule', {
                type: 'custom',
                message: t('applications.form.errors.duplicateIntitule'),
              })
            }
          }
        })
    } else {
      createApplication({
        idService: parseInt(data.idService),
        nomApplication: data.intitule,
        nomTechnique: data.nomTechnique,
        tags: selectedTags.map((tag) => tag.id),
      })
        .unwrap()
        .then((application) => {
          if (application.id) {
            navigate(
              generatePath(ROUTE_PATHS.PROMPTS, {
                idApplication: application.id,
              })
            )
            toast.success(t('applications.form.success'))
          }
        })
        .catch((error) => {
          if (error.status === 409) {
            if (error.data?.detail === ERROR_DUPLICATE_NOM_APPLICATION) {
              setError('intitule', {
                type: 'custom',
                message: t('applications.form.errors.duplicateIntitule'),
              })
            }
            if (error.data?.detail === ERROR_DUPLICATE_NOM_TECHNIQUE) {
              setError('nomTechnique', {
                type: 'custom',
                message: t('applications.form.errors.duplicateNomTechnique'),
              })
            }
          }
        })
    }
  })

  return {
    isLoading: isLoadingCreateApplication || isLoadingUpdateApplication,
    errors,
    register,
    onSubmit,
    isValid,
    setValue,
  }
}
