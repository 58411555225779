import { useParams } from 'react-router-dom'
import { useGetPromptQuery } from 'lauria/api/prompt'
import { skipToken } from '@reduxjs/toolkit/query'
import Loader from 'core/components/common/Loader'
import React from 'react'
import { useSelector } from 'react-redux'
import { promptLayout } from 'lauria/store/slices/promptLayout'
import { PromptNavigation } from 'lauria/components/prompts/PromptNavigation'
import { PromptContent } from 'lauria/components/prompts/PromptContent'

export const Prompt = () => {
  const { idPrompt } = useParams()
  const { application } = useSelector(promptLayout)
  const promptId = idPrompt ? parseInt(idPrompt, 10) : undefined

  const {
    data: prompt,
    isLoading: isLoadingPrompt,
    isFetching: isFetchingPrompt,
  } = useGetPromptQuery(promptId ? { promptId } : skipToken)

  if (isLoadingPrompt || isFetchingPrompt) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader pageCenter />
      </div>
    )
  }

  if (!application || !prompt) {
    return null
  }

  return (
    <>
      <PromptNavigation application={application} />
      <div className="prompt-content">
        {prompt && (
          <>
            <div className="flex flex-col w-full h-full">
              <PromptContent prompt={prompt} />
            </div>
          </>
        )}
      </div>
    </>
  )
}
