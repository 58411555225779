import { IPromptDetail } from 'core/interfaces/Prompt'
import Card from 'core/components/common/Card'
import React from 'react'
import { ReactComponent as PromptIconBanner } from 'lauria/assets/images/prompts/promptIconBanner.svg'
import { ReactComponent as InactivePromptIconBanner } from 'lauria/assets/images/prompts/inactiveBannerIconPrompt.svg'
import { NbDocIcon } from 'lauria/components/common/NbDocIcon'

export const PromptDiffusionCard: React.FC<{
  prompt: IPromptDetail
  isDeleted?: boolean
  onClick?: () => void
}> = ({ prompt, isDeleted, onClick = () => {} }) => {
  return (
    <Card
      className={`card-prompt-distribution ${isDeleted ? 'deleted' : 'with-hover'}`}
      withHoverStyle={false}
      onClick={onClick}
    >
      <div className="card-prompt-header-distribution">
        {prompt.actif ? <PromptIconBanner /> : <InactivePromptIconBanner />}
        <div className="flex flex-col ml-4">
          <div>{prompt.nomPrompt}</div>
          {prompt.documentsCount > 0 && (
            <NbDocIcon
              nbDoc={prompt.documentsCount}
              className={isDeleted || !prompt.actif ? 'bg-gray-1325' : ''}
            />
          )}
        </div>
      </div>
      <div className="md:line-clamp-3 line-clamp-2 leading-tight text-left px-30px">
        {prompt.prompt}
      </div>
    </Card>
  )
}
