import React, { useContext } from 'react'
import { ReactComponent as TagCloseButton } from 'lauria/assets/images/tags/closeTagButton.svg'
import { ReactComponent as TagCloseButtonDark } from 'core/assets/images/icones/deleteDark.svg'
import { DarkThemeContext } from 'core/contexts/DarkThemeContext'

export const TagWithDeleteButton: React.FC<{
  tag: { id: number; label: string }
  onClick: (id: number) => void
}> = ({ tag, onClick }) => {
  const { isDarkTheme } = useContext(DarkThemeContext)
  return (
    <div className="tag-button pr-2 group hover:bg-blue-1050 dark:hover:bg-gray-1450">
      <span>{tag.label}</span>
      <button
        type="button"
        onClick={() => onClick(tag.id)}
        className="delete-tag-button ml-3 focus:outline-none"
      >
        {isDarkTheme ? (
          <TagCloseButtonDark className="text-black" />
        ) : (
          <TagCloseButton />
        )}
      </button>
    </div>
  )
}
