import React, { createContext, useEffect, useState } from 'react'
import { kpiMetrics } from 'core/constants/kpi'
import { PERIOD } from 'core/constants/period'
import { getPeriodDates } from 'core/utils/service'
import { IKpiResponse, IMetric } from 'core/interfaces/Kpi'
import { IPromptDetail } from 'core/interfaces/Prompt'

interface KpiPromptContextType {
  activeMetric: IMetric
  setActiveMetric: React.Dispatch<React.SetStateAction<IMetric>>
  period: string
  setPeriod: React.Dispatch<React.SetStateAction<string>>
  currentDate: Date
  setCurrentDate: React.Dispatch<React.SetStateAction<Date>>
  start: Date
  setStart: React.Dispatch<React.SetStateAction<Date>>
  end: Date
  setEnd: React.Dispatch<React.SetStateAction<Date>>
  selectedPrompts: number[]
  setSelectedPrompts: React.Dispatch<React.SetStateAction<number[]>>
  promptsList: IPromptDetail[]
  setPromptsList: React.Dispatch<React.SetStateAction<IPromptDetail[]>>
  kpis: Record<string, IKpiResponse[]>
  setKpis: React.Dispatch<React.SetStateAction<Record<string, IKpiResponse[]>>>
  oldKpis: Record<string, IKpiResponse[]>
  setOldKpis: React.Dispatch<
    React.SetStateAction<Record<string, IKpiResponse[]>>
  >
  valueType: 'TOTAL' | 'AVERAGE'
  setValueType: React.Dispatch<React.SetStateAction<'TOTAL' | 'AVERAGE'>>
}

export const KpiPromptContext = createContext<KpiPromptContextType>({
  activeMetric: kpiMetrics[0],
  setActiveMetric: () => {},
  period: PERIOD.HEBDO,
  setPeriod: () => {},
  currentDate: new Date(),
  setCurrentDate: () => {},
  start: new Date(),
  setStart: () => {},
  end: new Date(),
  setEnd: () => {},
  selectedPrompts: [],
  setSelectedPrompts: () => {},
  promptsList: [],
  setPromptsList: () => {},
  kpis: {},
  setKpis: () => {},
  oldKpis: {},
  setOldKpis: () => {},
  valueType: 'TOTAL',
  setValueType: () => {},
})

export const KpiPromptContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [activeMetric, setActiveMetric] = useState(kpiMetrics[0])
  const [period, setPeriod] = useState<string>(PERIOD.HEBDO)
  const [currentDate, setCurrentDate] = useState(new Date())
  const [startDate, endDate] = getPeriodDates(period, currentDate)
  const [start, setStart] = useState(startDate)
  const [end, setEnd] = useState(endDate)
  const [selectedPrompts, setSelectedPrompts] = useState<number[]>([])
  const [promptsList, setPromptsList] = useState<IPromptDetail[]>([])
  const [kpis, setKpis] = useState<Record<string, IKpiResponse[]>>({})
  const [oldKpis, setOldKpis] = useState<Record<string, IKpiResponse[]>>({})
  const [valueType, setValueType] = useState<'TOTAL' | 'AVERAGE'>('TOTAL')
  useEffect(() => {
    const [newStartDate, newEndDate] = getPeriodDates(period, currentDate)
    setStart(newStartDate)
    setEnd(newEndDate)
  }, [period, currentDate])
  return (
    <KpiPromptContext.Provider
      value={{
        activeMetric,
        setActiveMetric,
        period,
        setPeriod,
        currentDate,
        setCurrentDate,
        start,
        setStart,
        end,
        setEnd,
        selectedPrompts,
        setSelectedPrompts,
        promptsList,
        setPromptsList,
        kpis,
        setKpis,
        oldKpis,
        setOldKpis,
        valueType,
        setValueType,
      }}
    >
      {children}
    </KpiPromptContext.Provider>
  )
}
