import { ReactComponent as DocIcon } from 'core/assets/images/documents/uploadDocument.svg'

export const NbDocIcon: React.FC<{
  nbDoc: number
  className?: string
  iconClassName?: string
}> = ({ nbDoc, className = '', iconClassName = 'h-[15px] w-[15px]' }) => {
  return (
    <div className={`nbDoc ${className}`}>
      <div>
        <DocIcon className={`${iconClassName} text-white mr-2`} />
      </div>
      <div className="font-medium">{nbDoc}</div>
    </div>
  )
}
