import { baseApi } from 'core/api/base'
import { generatePath } from 'react-router'
import {
  IPrompt,
  IPromptBatchBody,
  IPromptDetail,
} from 'core/interfaces/Prompt'
import { APPLICATION_ENDPOINT } from 'core/api/application'

const PROMTPS_ENDPOINT = '/prompts'
const PROMPT_LIST_BY_APP = '/prompts?application.id='
const PROMPT_ENDPOINT = `${PROMTPS_ENDPOINT}/:promptId`
const PROMPTS_BATCH_ENDPOINT = `${PROMTPS_ENDPOINT}/batch`
const PROMPTS_WITH_DETAILS_ENDPOINT = `${APPLICATION_ENDPOINT}/prompts-details`

const promptApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllPrompt: builder.query<IPrompt[], { applicationId: number }>({
      query: ({ applicationId }) => PROMPT_LIST_BY_APP + applicationId,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Prompt', id }) as const),
              { type: 'Prompt', id: 'LIST' },
            ]
          : [{ type: 'Prompt', id: 'LIST' }],
    }),
    getPromptsWithDetailsFromApplication: builder.query<
      IPromptDetail[],
      { applicationId: number }
    >({
      query: ({ applicationId }) =>
        generatePath(PROMPTS_WITH_DETAILS_ENDPOINT, {
          applicationId: applicationId.toString(),
        }),
    }),
    getPrompt: builder.query<IPrompt, { promptId: number }>({
      query: ({ promptId }) =>
        generatePath(PROMPT_ENDPOINT, {
          promptId: promptId.toString(),
        }),
      providesTags: (result, error, promptId) => [{ type: 'Prompt', promptId }],
    }),
    createUpdatePrompt: builder.mutation<
      IPrompt,
      {
        data: FormData
      }
    >({
      query: ({ data }) => ({
        url: generatePath(PROMTPS_ENDPOINT),
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result) =>
        result
          ? [
              { type: 'Prompt', id: 'LIST' },
              { type: 'Application', id: result.idApplication },
            ]
          : [{ type: 'Prompt', id: 'LIST' }],
    }),
    deletePrompt: builder.mutation<void, number>({
      query(promptId) {
        return {
          url: `/prompts/${promptId}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, id) => [{ type: 'Prompt', id }],
    }),
    batchPrompts: builder.mutation<void, IPromptBatchBody>({
      query: (body) => ({
        url: generatePath(PROMPTS_BATCH_ENDPOINT),
        method: 'POST',
        body: body,
      }),
    }),
  }),
})

export const {
  useGetAllPromptQuery,
  useGetPromptsWithDetailsFromApplicationQuery,
  useGetPromptQuery,
  useCreateUpdatePromptMutation,
  useDeletePromptMutation,
  useBatchPromptsMutation,
} = promptApi
export { promptApi }
