import { NavLink } from 'react-router-dom'
import { useLocation } from 'react-router'
import { IADOCS_URL } from 'core/constants/general'

export const Menu = () => {
  const menuItems = [
    { name: 'iastart', logo: 'logoIaStartDark.svg', route: '/' },
    {
      name: 'iadocs',
      logo: 'logoIaDocsDark.svg',
      route: IADOCS_URL,
    },
  ]
  const location = useLocation()

  return (
    <div className="side-menu">
      <nav className="flex flex-col items-start">
        {menuItems.map((item) => {
          const isActive =
            item.name === 'iastart'
              ? !location.pathname.startsWith('/iadocs')
              : location.pathname.startsWith(item.route)
          return (
            <NavLink
              key={item.name}
              to={item.route}
              className={`flex items-center p-4 w-full ${
                isActive ? 'menu-item-active' : ''
              }`}
            >
              <img
                src={require(`core/assets/images/logos/${item.logo}`)}
                alt={`${item.name} logo`}
                className="h-[50px]"
              />
            </NavLink>
          )
        })}
      </nav>
    </div>
  )
}
