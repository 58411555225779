import { ReactComponent as IconApp } from 'lauria/assets/images/kpi/iconApp.svg'
import { ReactComponent as IconMessage } from 'lauria/assets/images/kpi/iconMessage.svg'
import { ReactComponent as IconTraitement } from 'lauria/assets/images/kpi/iconTraitement.svg'
import { ReactComponent as IconCout } from 'lauria/assets/images/kpi/iconCout.svg'
import { ReactComponent as IconPertinence } from 'lauria/assets/images/kpi/iconePertinence.svg'

export const kpiMetrics = [
  {
    type: 'application',
    title: 'prompts.kpi.metrics.application.title',
    icon: IconApp,
    titleCol1: 'prompts.kpi.total',
    titleCol2: 'prompts.kpi.metrics.application.col2',
    totalField: 'total_conversation',
    moyenneField: 'moyenn_conv',
  },
  {
    type: 'messages',
    title: 'prompts.kpi.metrics.messages.title',
    icon: IconMessage,
    titleCol1: 'prompts.kpi.total',
    titleCol2: 'prompts.kpi.metrics.messages.col2',
    totalField: 'total_message',
    moyenneField: 'moyenne_message',
  },
  {
    type: 'traitement',
    title: 'prompts.kpi.metrics.traitement.title',
    icon: IconTraitement,
    titleCol1: 'prompts.kpi.total',
    titleCol2: 'prompts.kpi.metrics.traitement.col2',
    totalField: 'total_temps_traitement',
    moyenneField: 'moyenne_temps_traitement',
  },
  {
    type: 'cout',
    title: 'prompts.kpi.metrics.cout.title',
    icon: IconCout,
    titleCol1: 'prompts.kpi.total',
    titleCol2: 'prompts.kpi.metrics.cout.col2',
    totalField: 'total_cout',
    moyenneField: 'moyenne_cout',
  },
  {
    type: 'pertinence',
    title: 'prompts.kpi.metrics.pertinence.title',
    icon: IconPertinence,
    titleCol1: 'prompts.kpi.total',
    titleCol2: 'prompts.kpi.metrics.pertinence.col2',
    totalField: 'total_evaluation',
    moyenneField: 'moyenne_pertinence',
  },
]
export const RED_COLOR = '#B4465B'
export const BLUE_COLOR = '#3D7AB4'
export const YELLOW_COLOR = '#E3B14C'
