import { DarkThemeToggle } from 'core/components/layout/DarkThemeToggle'
import React, { useContext } from 'react'
import { generatePath, useLocation, useNavigate } from 'react-router'
import { ReactComponent as LogoLauria } from 'core/assets/images/general/logoLauria.svg'
import { ReactComponent as IconeProfile } from 'lauria/assets/images/common/iconeProfile.svg'
import { UserContext } from 'core/contexts/UserContext'
import { BACKOFFICE_PATH, ROUTE_PATHS } from 'core/constants/routePath'

export const LayoutHeader: React.FC<{ displayProfileIcone?: boolean }> = ({
  displayProfileIcone = false,
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { isAdmin, user } = useContext(UserContext)
  const goHome = () => {
    if (location.pathname.startsWith(BACKOFFICE_PATH)) {
      navigate(generatePath(BACKOFFICE_PATH))
    } else {
      navigate(generatePath('/'))
    }
  }
  const goToProfiles = () => {
    navigate(generatePath(ROUTE_PATHS.PROFILES))
  }

  return (
    <div className="layout-header">
      <div>
        <LogoLauria className="logo-lauria" onClick={goHome} />
      </div>
      <div className="option-header">
        <DarkThemeToggle />
        {(isAdmin || user?.superAdmin) && displayProfileIcone && (
          <IconeProfile className="profile-option" onClick={goToProfiles} />
        )}
      </div>
    </div>
  )
}
