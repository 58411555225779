import React, { SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalWrapper from 'core/components/common/ModalWrapper'
import { ModalHeader } from 'core/components/common/ModalHeader'
import { ModalBody } from 'core/components/common/ModalBody'
import { TagSelect } from 'lauria/components/dashboard/TagSelect'
import { IApplication } from 'core/interfaces/Application'
import { applicationApi } from 'core/api/application'
import Loader from 'core/components/common/Loader'
import { TagWithDeleteButton } from 'lauria/components/common/TagWithDeleteButton'

export const FilterModal: React.FC<{
  open: boolean
  setOpen: (value: SetStateAction<boolean>) => void
  setApplications: (value: SetStateAction<IApplication[]>) => void
}> = ({ open, setOpen, setApplications }) => {
  const { t } = useTranslation()
  const [selectedTags, setSelectedTags] = useState<
    { id: number; label: string }[]
  >([])
  const handleTagRemove = (id: number) => {
    setSelectedTags(
      selectedTags.filter((tag: { id: number; label: string }) => tag.id !== id)
    )
  }
  const [trigger, { isLoading }] =
    applicationApi.endpoints.getAllApplications.useLazyQuery()

  const filterApp = () => {
    const tags = selectedTags.map((tag) => tag.id)

    trigger({ tags })
      .unwrap()
      .then((applications) => {
        setApplications(applications)
        setOpen(false)
      })
  }

  if (isLoading) {
    return <Loader center />
  }

  return (
    <ModalWrapper
      classNames={{ modal: 'modal modal-application' }}
      onClose={() => setOpen(false)}
      open={open}
      showCloseIcon={false}
    >
      <ModalHeader>{t('applications.filter.modalTitle')}</ModalHeader>
      <ModalBody className="m-[30px]">
        <TagSelect
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        />
        {selectedTags.length > 0 && (
          <div className="tags">
            <div className="tags-list">
              {selectedTags.map((tag) => (
                <TagWithDeleteButton
                  key={tag.id}
                  tag={tag}
                  onClick={handleTagRemove}
                />
              ))}
            </div>
          </div>
        )}
        <button className="btn default w-full mt-[30px]" onClick={filterApp}>
          {t('applications.filter.modalButton')}
        </button>
      </ModalBody>
    </ModalWrapper>
  )
}
