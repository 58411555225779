import React, { SetStateAction, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import ModalWrapper from 'core/components/common/ModalWrapper'
import { ModalHeader } from 'core/components/common/ModalHeader'
import { ModalBody } from 'core/components/common/ModalBody'
import { ReactComponent as ActivePromptIcon } from 'lauria/assets/images/prompts/activePromptFilter.svg'
import { ReactComponent as InactivePromptIcon } from 'lauria/assets/images/prompts/inactivePromptFilter.svg'
import { ReactComponent as ActivePromptIconDark } from 'lauria/assets/images/prompts/activePromptFilterDark.svg'
import { ReactComponent as InactivePromptIconDark } from 'lauria/assets/images/prompts/inactivePromptFilterDark.svg'
import { DarkThemeContext } from 'core/contexts/DarkThemeContext'

export const PromptFilterModal: React.FC<{
  open: boolean
  setOpen: (value: SetStateAction<boolean>) => void
  setShowInactive: (value: SetStateAction<boolean>) => void
}> = ({ open, setOpen, setShowInactive }) => {
  const { t } = useTranslation()
  const { isDarkTheme } = useContext(DarkThemeContext)
  const handleFilter = (value: boolean) => {
    setShowInactive(value)
    setOpen(false)
  }

  return (
    <ModalWrapper
      classNames={{ modal: 'modal modal-prompt-filter' }}
      onClose={() => setOpen(false)}
      open={open}
      showCloseIcon={false}
    >
      <ModalHeader>{t('prompts.filterModal.title')}</ModalHeader>
      <ModalBody className="mx-[30px] mt-[15px] mb-[40px]">
        <div className="flex flex-col">
          <div>{t('prompts.filterModal.text')}</div>
          <div
            className="card-filter my-[30px]"
            onClick={() => handleFilter(false)}
          >
            {isDarkTheme ? <ActivePromptIconDark /> : <ActivePromptIcon />}
            <span className="ml-[20px]">{t('prompts.filterModal.actif')}</span>
          </div>
          <div className="card-filter" onClick={() => handleFilter(true)}>
            {isDarkTheme ? <InactivePromptIconDark /> : <InactivePromptIcon />}
            <span className="ml-[20px]">
              {t('prompts.filterModal.inactif')}
            </span>
          </div>
        </div>
      </ModalBody>
    </ModalWrapper>
  )
}
