import { useGetApplicationQuery } from 'core/api/application'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { setApplication } from 'lauria/store/slices/promptLayout'
import { skipToken } from '@reduxjs/toolkit/query'
import { useEffect } from 'react'

export const useLayoutApplication = () => {
  const dispatch = useDispatch()
  const { idApplication } = useParams()

  const applicationId = idApplication ? parseInt(idApplication, 10) : undefined
  const {
    data: application,
    isLoading,
    refetch,
  } = useGetApplicationQuery(applicationId ? { applicationId } : skipToken)

  useEffect(() => {
    if (application) {
      dispatch(setApplication(application))
    }
  }, [application, dispatch])

  return {
    application,
    isLoading,
    refetch,
  }
}
