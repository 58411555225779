import { FieldLabel } from 'core/components/common/FieldLabel'
import { FormSelect } from 'core/components/common/FormSelect'
import React from 'react'
import { FieldError, UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useGetAllServiceQuery } from 'lauria/api/service'
import { ApplicationForm } from 'lauria/components/applications/FormApplicationModalContent'

export const ServiceSelect: React.FC<{
  error?: FieldError
  register?: UseFormRegister<ApplicationForm>
  boldLabel?: boolean
  placeholder: string
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
  value?: string
  displayDefaultOption?: boolean
  labelKey?: string
  required?: boolean
  width?: string
}> = ({
  error,
  register = () => {},
  boldLabel = false,
  placeholder,
  onChange,
  value = '',
  displayDefaultOption = false,
  labelKey,
  required = true,
  width = '[300px]',
}) => {
  const { t } = useTranslation()
  const { data: services, isLoading } = useGetAllServiceQuery()
  const serviceOptions = services?.map((service) => {
    return { value: service.id.toString(), label: service.nomService }
  })
  return (
    <div className="md:flex md:flex-col">
      <FieldLabel
        className={`h-[45px] w-${width} flex items-center ${boldLabel ? 'font-semibold' : ''}`}
        label={labelKey ? t(labelKey) : t('applications.form.serviceLabel')}
        required={required}
      />
      <FormSelect
        placeholder={placeholder}
        options={serviceOptions ?? []}
        fieldName="idService"
        register={register}
        error={error}
        wrapperClassName={`md:w-${width} md:mb-0 mb-30px`}
        wrapperErrorClassName="h-[30px]"
        isLoading={isLoading}
        onChange={onChange}
        value={value}
        required={required}
        displayDefaultOption={displayDefaultOption}
      />
    </div>
  )
}
