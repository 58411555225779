import { useTranslation } from 'react-i18next'
import Loader from 'core/components/common/Loader'
import { useCreatePromptForm } from 'lauria/hooks/useCreatePromptForm'
import React, { useContext, useEffect, useState } from 'react'
import { ActivePromptModal } from 'lauria/components/prompts/ActivePromptModal'
import { PromptFormFields } from 'lauria/components/prompts/PromptFormFields'
import { PromptFormFieldsExpert } from 'lauria/components/prompts/PromptFormFieldsExpert'
import { PromptFormFieldsAssisted } from 'lauria/components/prompts/PromptFormFieldsAssisted'
import { ReactComponent as AddDocuments } from 'lauria/assets/images/mobile/addDocuments.svg'
import { ReactComponent as AddDocumentsDark } from 'lauria/assets/images/mobile/addDocumentsDark.svg'
import { DarkThemeContext } from 'core/contexts/DarkThemeContext'
import { ReactComponent as BackArrowIcon } from 'core/assets/images/icones/backArrow.svg'
import { PromptTest } from 'lauria/components/prompts/PromptTest'
import { PromptDocumentsSection } from 'lauria/components/prompts/PromptDocumentsSection'
import { IApplicationService } from 'core/interfaces/Application'
import { PromptFormSide } from 'lauria/components/prompts/PromptFormSide'

interface PromptFormProps {
  service: IApplicationService
}

export const PromptForm: React.FC<PromptFormProps> = ({ service }) => {
  const { t } = useTranslation()
  const { isDarkTheme } = useContext(DarkThemeContext)
  const [displayDocs, setDisplayDocs] = useState(false)

  const {
    state,
    setters,
    fields,
    promptRef,
    tacheRef,
    addTextToTextarea,
    errors,
    onFormSubmit,
    handleConfirm,
    handleCancel,
    cancel,
    changeMode,
    setValue,
    modeles,
    register,
    getValues,
  } = useCreatePromptForm()
  const returnToForm = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setDisplayDocs(false)
  }

  if (state.isLoading) {
    return (
      <Loader
        center
        className="h-screen flex items-center justify-center w-full"
      />
    )
  }
  return (
    <>
      <div className="h-full w-full flex flex-col md:text-[13px]">
        <div className="prompt-form-wrapper">
          <div
            className={`prompt-form-fields-content ${displayDocs ? 'hidden' : 'flex'}`}
          >
            <form>
              {modeles && (
                <PromptFormFields
                  nomField={fields.nomField}
                  errors={errors}
                  assistedMode={state.assistedMode}
                  changeMode={changeMode}
                  temperatureField={fields.temperatureField}
                  setValue={setValue}
                  modeleList={modeles}
                  register={register}
                  modeleField={fields.modeleField}
                />
              )}
              {![2, 4].includes(service.id) && (
                <div className="prompt-documents-section">
                  <div className="">{t('prompts.form.attachDocuments')}</div>
                  <PromptDocumentsSection
                    documents={state.documents}
                    setDocuments={setters.setDocuments}
                    newFiles={state.newFiles}
                    setNewFiles={setters.setNewFiles}
                  />
                </div>
              )}
              {state.assistedMode ? (
                <PromptFormFieldsAssisted
                  tacheField={fields.tacheField}
                  roleField={fields.roleField}
                  contexteField={fields.contexteField}
                  formatField={fields.formatField}
                  tacheRef={tacheRef}
                  addTextToTextarea={addTextToTextarea}
                  errors={errors}
                />
              ) : (
                <PromptFormFieldsExpert
                  promptField={fields.promptField}
                  promptRef={promptRef}
                  addTextToTextarea={addTextToTextarea}
                  errors={errors}
                />
              )}
              <div className="button-wrapper-right">
                <button
                  className="btn cancel w-full md:w-[180px] md:block hidden"
                  onClick={cancel}
                >
                  {t('global.form.button.cancel')}
                </button>
                <button
                  className={`btn default submit prompt-btn-submit ${displayDocs ? 'hidden' : 'block'}`}
                  onClick={(event) => {
                    event.preventDefault()
                    onFormSubmit()
                  }}
                >
                  {t('prompts.form.button.validate')}
                </button>
                <button
                  className={`btn w-full relative md:hidden h-[60px] bg-gray-1325 rounded-[0] ${displayDocs ? 'block' : 'hidden'}`}
                  onClick={(event) => returnToForm(event)}
                >
                  <span className="absolute left-[20px] bottom-[20px]">
                    <BackArrowIcon className="icon" />
                  </span>
                  {t('prompts.form.button.promptReturn')}
                </button>
                <div
                  className={`md:hidden fixed bottom-3.5 right-3.5 ${!displayDocs ? 'flex' : 'hidden'} flex-row`}
                  onClick={() => setDisplayDocs(!displayDocs)}
                >
                  {isDarkTheme ? <AddDocumentsDark /> : <AddDocuments />}
                </div>
              </div>
            </form>
          </div>
          <PromptFormSide
            getValues={getValues}
            assistedMode={state.assistedMode}
            modeles={modeles}
          />
        </div>
      </div>
      <ActivePromptModal
        setOpen={setters.setOpen}
        open={state.open}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />
    </>
  )
}
