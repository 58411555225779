import { IUserColumn } from 'core/interfaces/User'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  useReactTable,
} from '@tanstack/react-table'
import { ReactComponent as NewProfile } from 'lauria/assets/images/common/newProfile.svg'
import { ReactComponent as NewProfileDark } from 'lauria/assets/images/common/newProfileDark.svg'
import { DarkThemeContext } from 'core/contexts/DarkThemeContext'
import SortableHeader from 'lauria/components/profiles/SortableHeader'
import { Pagination } from 'lauria/components/profiles/Pagination'
import { generatePath, useNavigate } from 'react-router'
import { ROUTE_PATHS } from 'core/constants/routePath'

export const ProfilesList: React.FC<{ users: IUserColumn[] }> = ({ users }) => {
  const { t } = useTranslation()
  const { isDarkTheme } = useContext(DarkThemeContext)
  const navigate = useNavigate()
  const changePage = (index: number) => {
    table.setPageIndex(index - 1)
  }
  const createProfile = () => {
    navigate(generatePath(ROUTE_PATHS.PROFILES_CREATE))
  }
  const editProfile = (idProfile: number) => {
    navigate(generatePath(ROUTE_PATHS.PROFILES_EDIT, { idProfile }))
  }
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  })
  const columnHelper = createColumnHelper<IUserColumn>()
  const columns = [
    columnHelper.accessor('nom', {
      header: ({ column }) => (
        <SortableHeader label={t('profiles.nom')} column={column} />
      ),
      enableSorting: true,
    }),
    columnHelper.accessor('prenom', {
      header: t('profiles.prenom'),
    }),
    columnHelper.accessor('email', {
      header: t('profiles.email'),
    }),
    columnHelper.accessor('role', {
      header: ({ column }) => (
        <SortableHeader label={t('profiles.role')} column={column} />
      ),
      enableSorting: true,
    }),
    columnHelper.accessor('dateModification', {
      header: ({ column }) => (
        <SortableHeader label={t('profiles.updated')} column={column} />
      ),
      enableSorting: true,
    }),
    columnHelper.display({
      id: 'actions',
      header: () => (
        <button onClick={createProfile} className="btn-new-profile">
          {t('profiles.newProfile')}
          {isDarkTheme ? (
            <NewProfileDark className="ml-auto" />
          ) : (
            <NewProfile className="ml-auto" />
          )}
        </button>
      ),
      cell: () => null,
    }),
  ]
  const table = useReactTable({
    data: users,
    columns,
    initialState: {
      sorting: [
        {
          id: 'nom',
          desc: false,
        },
      ],
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination,
    },
  })

  return (
    <>
      <table className="table-profile">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              className="hover:bg-gray-50"
              onClick={() => editProfile(row.original.id)}
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        nbPage={table.getPageCount()}
        currentPage={table.getState().pagination.pageIndex + 1}
        onChangePage={changePage}
      />
    </>
  )
}
